import { Injectable } from '@angular/core';
import { Tenant, User } from '@incendi-io/types';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GtmService {
  constructor() {
    this.addGtmScript();
  }

  public initialize(profile: User, tenant: Tenant) {
    if (!environment.production) {
      return;
    }

    // TODO Initialize user and tenant
  }

  public emitEvent(eventCategory: string, eventLabel = 'View More', eventAction = 'click') {
    if (!environment.production) {
      return;
    }

    // gtag('event', eventAction, {
    //   event_category: eventCategory,
    //   eventAction,
    //   event_label: eventLabel
    // });
  }

  private addGtmScript(): void {
    if (!environment.production) {
      return;
    }

    (window as any).dataLayer = (window as any).dataLayer || [];
    this.sendData({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js'
    });

    const script = document.createElement<'script'>('script');
    script.src = `https://www.googletagmanager.com/gtm.js?id=${environment.gtmId}`;
    document.body.appendChild(script);
  }

  private sendData(data: any): void {
    (window as any).dataLayer.push(data);
  }
}
