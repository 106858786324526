import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Team } from '@incendi-io/types';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { mapTo, tap } from 'rxjs/operators';

import { appConstants } from '../../app.constants';
import { AuthService } from '../oauth0/oauth0.service';
import { BaseFirebaseService } from './base-firebase.service';

@Injectable({
  providedIn: 'root'
})
export class TeamsService extends BaseFirebaseService<Team> {
  private teams: Team[] | undefined;
  teamFilter$ = new BehaviorSubject<string | undefined>(undefined);

  constructor(
    auth: AuthService,
    db: AngularFirestore
  ) {
    super(auth, db, appConstants.firebase.collections.teams);
  }

  public getTeams(force = false): Observable<Team[]> {
    if (!force && this.teams) {
      return of(this.teams);
    }
    return this.getList().pipe(tap(res => this.teams = res));
  }

  public updateCache(): Observable<void> {
    return this.getTeams(true).pipe(mapTo(void 0));
  }

  getCurrentTeam(): string | undefined {
    const teamValue = this.teamFilter$.value;
    if (teamValue) {
      return this.teamFilter$.value;
    } else {
      if (this.auth.profile) {
        if (this.auth.profile.teamId) {
          return this.auth.profile.teamId;
        } else {
          return undefined;
        }
      } else {
        return undefined;
      }
    }
  }

  switchTeam(teamId: string) {
    this.teamFilter$.next(teamId);
  }
}
