import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Entity } from '@incendi-io/types';

import { appConstants } from '../../app.constants';
import { AuthService } from '../oauth0/oauth0.service';
import { BaseFirebaseService } from './base-firebase.service';

@Injectable({
  providedIn: 'root'
})
export class EntityService extends BaseFirebaseService<Entity> {
  constructor(
    auth: AuthService,
    db: AngularFirestore
  ) {
    super(auth, db, appConstants.firebase.collections.entities);
  }
}
