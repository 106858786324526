export enum ROUTER_LINKS {
  ANALYTICS = 'analytics',
  DEACTIVATED = 'deactivated',
  HOME = 'home',
  HOME_CONTENT = 'home-content',
  LOGOUT = 'logout',
  ONBOARDING = 'onboarding',
  PREVIEW = 'preview',
  PREVIEW_CONTENT = 'preview-content',
  REGISTRY_REVIEW = 'registryreview',
  REPORTS = 'reports',
  SETTINGS = 'settings',
  STAFF = 'staff',
  TEAM = 'team'
}

export enum STAFF_ROUTER_LINKS {
  AREAS = 'areas',
  CURRENCIES = 'currencies',
  DIVISIONS = 'divisions',
  ENTITIES = 'entities',
  LOCATIONS = 'locations',
  POSITIONS = 'positions',
  ROLES = 'roles',
  USERS = 'users'
}
