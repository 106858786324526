<app-modal-header [label]="options.label" (closeDialog)="close()"></app-modal-header>

<div class="modal-body text-center" *transloco="let translate;">
  <app-user-avatar class="initials"
                   [status]="options.employee.status"
                   [firstName]=" options.employee.firstName || ''"
                   [lastName]=" options.employee.lastName || ''"
                   [userId]=" options.employee.userId || ''"></app-user-avatar>
  <p class="message">{{ translate(options.message) }}</p>
  <p class="username">{{ options.employee.fullName }}</p>
</div>

<div class="modal-footer justify-content-between" *transloco="let translate;">
  <button type="button" *ngIf="options.no" class="btn btn-info btn-lg" (click)="close()">
    <svg *ngIf="options.no.icon">
      <use [attr.xlink:href]="'#' + options.no.icon"></use>
    </svg>
    <span *ngIf="options.no.label">{{ translate(options.no.label) }}</span>
  </button>

  <button type="button"
          *ngIf="options.yes"
          class="btn btn-danger btn-lg {{options.yes.buttonClass || ''}}"
          (click)="confirmAction()">
    <svg *ngIf="options.yes.icon">
      <use [attr.xlink:href]="'#' + options.yes.icon"></use>
    </svg>
    <span *ngIf="options.yes.label">{{ translate(options.yes.label) }}</span>
  </button>
</div>
