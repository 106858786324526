import { AlgoliaQueue, CommonAlgolia, Tenant } from '@incendi-io/types';
import { AngularFirestore, CollectionReference, Query } from '@angular/fire/compat/firestore';
import { BaseFirebaseService, CustomQuery } from './base-firebase.service';
import {
  MultipleQueriesQuery,
  MultipleQueriesResponse,
  SearchForFacetValuesResponse,
  SearchOptions,
  SearchResponse
} from '@algolia/client-search';
import { Observable, from } from 'rxjs';
import { SearchClient, SearchIndex, default as algoliaSearch } from 'algoliasearch/lite';

import { AuthService } from '../oauth0/oauth0.service';
import { Injectable } from '@angular/core';
import { RequestOptions } from '@algolia/transporter';
import { appConstants } from '../../app.constants';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AlgoliaService extends BaseFirebaseService {
  private static readonly defaultSearchParams: SearchOptions = {
    attributesToHighlight: [],
    hitsPerPage: appConstants.algolia.defaultPageSize,
    sortFacetValuesBy: 'alpha',
    typoTolerance: false
  };

  private client: SearchClient | undefined;
  private region: string | undefined;
  private currentIndex: string | undefined;

  constructor(auth: AuthService, db: AngularFirestore) {
    super(auth, db, appConstants.firebase.collections.algoliaQueue);
  }

  public initClient(tenant: Tenant, searchApiKey: string): void {
    this.region = tenant.country;
    this.client = algoliaSearch(environment.algoliaAppId, searchApiKey);
  }

  public search<T = any>(
    indexName: string,
    query: string,
    params?: RequestOptions & SearchOptions
  ): Observable<SearchResponse<T>> {
    const index = this.getIndex(indexName);
    if (!index) {
      throw new Error('No search index. You must call initClient first.');
    }
    const options = { ...AlgoliaService.defaultSearchParams, ...params };
    return from(index.search<T>(query, options));
  }

  public facetedSearch<T = any>(queries: MultipleQueriesQuery[]): Observable<MultipleQueriesResponse<T>> {
    if (!this.client) {
      throw new Error('No search index. You must call initClient first.');
    }
    return from(this.client.search<T>(queries));
  }

  public prepareMultipleQuery(indexName: string, query: string, params?: SearchOptions): MultipleQueriesQuery[] {
    const facets = params?.facets || [];
    const filters = params?.filters || '';
    const options = { ...AlgoliaService.defaultSearchParams, ...params };
    const multiQuery: MultipleQueriesQuery[] = [{ indexName, query, params: options }];

    facets
      .filter(facet => filters.includes(`${facet}:`))
      .forEach(facet => {
        // Remove filter for current facet
        const facetFilters = filters
          .split(' AND ')
          .filter(part => !part.includes(facet))
          .join(' AND ');

        // Get info only for current facet applying other filters
        multiQuery.push({
          indexName,
          query,
          params: {
            ...params,
            facets: [facet],
            filters: facetFilters,
            hitsPerPage: 0
          }
        });
      });

    return multiQuery;
  }

  public searchFacets(
    indexName: string,
    facet: string,
    query: string,
    params?: SearchOptions
  ): Observable<SearchForFacetValuesResponse> {
    const index = this.getIndex(indexName);
    if (!index) {
      throw new Error('No search index. You must call initClient first.');
    }
    return from(index.searchForFacetValues(facet, query, params));
  }

  public clearCache(): Observable<void> {
    if (!this.client) {
      throw new Error('You must call initClient first.');
    }
    return from(this.client.clearCache());
  }

  public getQueue$<T = CommonAlgolia>(index: string, customQuery?: CustomQuery[]): Observable<AlgoliaQueue<T>[]> {
    this.currentIndex = index;
    return this.getLiveList$(customQuery);
  }

  private getIndex(index: string): SearchIndex | null {
    if (!this.client) {
      return null;
    }
    return this.client.initIndex(index);
  }

  protected queryFunction(ref: CollectionReference): Query {
    return super
      .queryFunction(ref)
      .where('indexName', '==', this.currentIndex)
      .where('tenantRegion', '==', this.region);
  }
}
