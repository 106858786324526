import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

import { SnackBarOptions } from './snack-bar-options.interface';

@Component({
  selector: 'app-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss']
})
export class SnackBarComponent {
  title: string;
  icon: string;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: SnackBarOptions) {
    this.title = data.title;
    this.icon = data.icon || '';
  }
}
