import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, Self } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { of } from 'rxjs';
import { catchError, exhaustMap, filter, switchMap, take } from 'rxjs/operators';

import { AuthService } from './core/oauth0/oauth0.service';
import { AutoUnsubscribeService } from './core/services/auto-unsubscribe.service';
import { UsersService } from './core/services/users.service';
import { ROUTER_LINKS } from './interfaces/router-links.enum';
import { ConfirmService } from './shared/confirm/confirm.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [AutoUnsubscribeService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
  public showLoader = true;
  public isLoggedIn = false;
  public showNavbar = false;

  constructor(
    @Self() private unsub: AutoUnsubscribeService,
    private router: Router,
    private auth: AuthService,
    private cdr: ChangeDetectorRef,
    private confirm: ConfirmService,
    private usersService: UsersService,
  ) {
  }

  public ngOnInit(): void {
    this.isLoggedIn = !!this.auth.profile;
    this.showNavbar = this.isNavbarRoute(location.href);

    this.unsub.subs = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(event => {
        this.showNavbar = this.isNavbarRoute((event as NavigationEnd).urlAfterRedirects);
        this.cdr.markForCheck();
      });

    this.checkTransfer();
  }

  public onActivate(): void {
    this.showLoader = false;
    this.cdr.markForCheck();
  }

  private isNavbarRoute(route: string): boolean {
    return [
      ROUTER_LINKS.HOME,
      ROUTER_LINKS.STAFF,
      ROUTER_LINKS.TEAM,
      ROUTER_LINKS.REGISTRY_REVIEW,
      ROUTER_LINKS.ANALYTICS,
      ROUTER_LINKS.SETTINGS,
      ROUTER_LINKS.PREVIEW
    ].some(link => route.includes(link));
  }

  private checkTransfer(): void {
    this.unsub.subs = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd && !(event as NavigationEnd).urlAfterRedirects.includes(ROUTER_LINKS.ONBOARDING)),
        take(1),
        switchMap(() => this.auth.getLiveProfile$()),
        filter((user) => Boolean(user && user.firstLogin && user.transferManagerId)),
        exhaustMap((user) =>
          this.confirm
            .open({
              buttons: [
                {
                  class: 'btn btn-lg btn-active m-auto',
                  label: 'joinTeam',
                  value: 'join',
                },
                {
                  class: 'btn btn-lg btn-info m-auto',
                  label: 'declineInvitation',
                  value: 'decline',
                },
              ],
              coloredHeader: true,
              icon: 'group',
              label: 'inviteTeamTitle',
              message: 'inviteTeamMessage',
              panelClass: 'team-invitation',
              params: {
                manager: user!.transferManagerFullName,
              },
              backdrop: 'static',
              keyboard: false,
              hideClose: true
            })
            .pipe(
              switchMap((answer: string) => {
                switch (answer) {
                  case 'join':
                    return this.usersService.joinTeam(user!)
                  case 'decline':
                    return this.usersService.declineTeam(user!)
                  default:
                    return of(false)
                }
              }),
              catchError((err) => {
                console.error(err)
                return of(void 0)
              }),
            ),
        ),
      )
      .subscribe()
  }
}
