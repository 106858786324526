import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, Self } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { AutoUnsubscribeService } from '../../core/services/auto-unsubscribe.service';

@Component({
  selector: 'app-icons',
  templateUrl: './icons.component.html',
  styleUrls: ['./icons.component.scss'],
  providers: [AutoUnsubscribeService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconsComponent implements OnInit {
  public icons: SafeHtml = '';

  constructor(
    @Self() private unsub: AutoUnsubscribeService,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef
  ) {
  }

  public ngOnInit(): void {
    this.unsub.subs = this.http.get('/assets/icons/icon-set.svg', { responseType: 'text' })
      .subscribe(res => {
        this.icons = this.sanitizer.bypassSecurityTrustHtml(res);
        this.cdr.markForCheck();
      });
  }
}
