import { Component, HostBinding } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HashMap } from '@ngneat/transloco';

import { FormControl, Validators } from '@angular/forms';
import { ConfirmButton } from './confirm.interface';

@Component({
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent {
  public buttons!: ConfirmButton[];
  public message?: string;
  public label!: string;
  public params?: HashMap;
  public item?: string;
  public icon?: string;
  public inputPlaceholder?: string;
  public showInput?: boolean;
  public hideClose?: boolean;
  @HostBinding('class.colored-header') public coloredHeader = false;
  public inputControl = new FormControl('', [Validators.required]);
  constructor(private activeModal: NgbActiveModal) {}

  public buttonClick(value: any): void {
    if (this.showInput && value && this.inputControl.invalid) {
      this.inputControl.markAsTouched();
      return;
    }
    this.activeModal.close(this.showInput && value ? this.inputControl.value : value);
  }

  public close(): void {
    this.activeModal.dismiss();
  }
}
