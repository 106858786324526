import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { from, Observable } from 'rxjs';

import { EmployeeConfirmOptions } from './employee-confirm-options.interface';
import { EmployeeConfirmComponent } from './employee-confirm.component';

@Injectable()
export class EmployeeConfirmService {
  constructor(private modal: NgbModal) {
  }

  public open(options: EmployeeConfirmOptions): Observable<boolean> {
    const modal = this.modal.open(
      EmployeeConfirmComponent,
      {
        centered: true,
        windowClass: `your-trust-details-modal confirm-modal ${options.windowClass || ''}`
      }
    );
    (modal.componentInstance as EmployeeConfirmComponent).options = options;
    return from(modal.result.catch(() => false));
  }
}
