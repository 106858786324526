import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';

import { AlgoliaAutocompleteComponent } from './algolia-autocomplete.component';

@NgModule({
  declarations: [
    AlgoliaAutocompleteComponent
  ],
  exports: [
    AlgoliaAutocompleteComponent
  ],
  imports: [
    CommonModule,
    MatAutocompleteModule,
    MatInputModule,
    ReactiveFormsModule,
    ScrollingModule
  ]
})
export class AlgoliaAutocompleteModule {}
