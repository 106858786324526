import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appAutoBlur]'
})
export class AutoBlurDirective {

  constructor(private element: ElementRef) {
  }

  @HostListener('window:mouseup') mouseUp() {
    setTimeout(() => this.element.nativeElement.blur(), 100);
  }

}
