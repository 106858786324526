<div class="watermark-wrapper" *transloco="let translate;">
  <div class="watermark-icon">
    <svg>
      <use [attr.xlink:href]="'#' + icon"></use>
    </svg>
  </div>
  <div class="watermark-title">{{translate(title)}}</div>
  <div class="watermark-description">{{translate(description)}}</div>
  <!--  Keep this formatting for watermark-content, css empty selector is used-->
  <div class="watermark-content"><ng-content></ng-content></div>
</div>
