import { FocusMonitor } from '@angular/cdk/a11y';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Observable, Subject } from 'rxjs';
import { debounceTime, filter, map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: AutocompleteComponent
    }
  ]
})
export class AutocompleteComponent
  implements ControlValueAccessor, MatFormFieldControl<AutocompleteComponent>, OnInit, OnDestroy
{
  private static instanceCount = 0;

  @Input() name = `autocomplete-${AutocompleteComponent.instanceCount}`;

  @Input() options: any[] = [];

  @Input() labelField: any;

  @Input() sort = true;

  @HostBinding('class.app-autocomplete')
  private readonly parentClass = true;

  @HostBinding() id = `autocomplete-${AutocompleteComponent.instanceCount}`;

  @HostBinding('class.floating')
  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty || !!this.control.value;
  }

  public disabled = false;
  public value: any;
  public control = new FormControl();
  public filteredOptions!: Observable<any[]>;
  public stateChanges = new Subject<void>();
  public placeholder = '';
  public focused = false;
  public required = false;

  public get errorState(): boolean {
    return (this.ngControl.invalid && this.ngControl.touched) || false;
  }

  public get empty(): boolean {
    return !this.value;
  }

  private onChange?: (value: any) => void;
  private onTouched?: () => void;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private fm: FocusMonitor,
    private elRef: ElementRef<HTMLElement>,
    private cdr: ChangeDetectorRef
  ) {
    AutocompleteComponent.instanceCount++;

    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  public ngOnInit(): void {
    this.fm.monitor(this.elRef, true).subscribe(origin => {
      this.focused = !!origin;
      this.stateChanges.next();
    });

    this.filteredOptions = this.control.valueChanges.pipe(
      startWith(''),
      debounceTime(100),
      filter(val => typeof val === 'string'),
      map(val => {
        const term = (val as string).trim().toLowerCase();
        return this.options.filter(option => {
          const label = this.getOptionLabel(option);
          return label.trim().toLowerCase().includes(term);
        });
      }),
      map(options => {
        return !this.sort
          ? options
          : options.sort((a: any, b: any) => {
              return this.getOptionLabel(a).localeCompare(this.getOptionLabel(b));
            });
      })
    );
  }

  public ngOnDestroy(): void {
    this.stateChanges.complete();
    this.fm.stopMonitoring(this.elRef);
  }

  public writeValue(value: any): void {
    this.value = value;
    this.control.setValue(value);
    this.stateChanges.next();
    this.cdr.markForCheck();
  }

  public registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }

  public touch(): void {
    if (this.onTouched) {
      this.onTouched();
    }
  }

  public getOptionLabel(option: any): string {
    return (this.labelField ? option?.[this.labelField] : option) || '';
  }

  public setDescribedByIds(): void {}

  public onContainerClick(): void {}

  public displayFn = (option: any): string => {
    return this.getOptionLabel(option);
  };

  public selectOption(event: MatAutocompleteSelectedEvent): void {
    this.value = event.option.value;
    if (this.onChange) {
      this.onChange(this.value);
    }
  }
}
