import { SearchOptions } from '@algolia/client-search';
import { FocusMonitor } from '@angular/cdk/a11y';
import { ScrollDispatcher } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatFormFieldControl } from '@angular/material/form-field';
import { CommonAlgolia } from '@incendi-io/types';
import { merge, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, finalize, mapTo, switchMap, take, tap } from 'rxjs/operators';

import { AlgoliaService } from '../../core/services/algolia.service';
import { AutoUnsubscribeService } from '../../core/services/auto-unsubscribe.service';

type AutocompleteItem = CommonAlgolia & Record<string, any>;

@Component({
  selector: 'app-algolia-autocomplete',
  templateUrl: './algolia-autocomplete.component.html',
  styleUrls: ['./algolia-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: AlgoliaAutocompleteComponent
    },
    AutoUnsubscribeService
  ]
})
export class AlgoliaAutocompleteComponent implements ControlValueAccessor, MatFormFieldControl<AlgoliaAutocompleteComponent>, OnInit, OnDestroy {
  private static instanceCount = 0;

  @ViewChild('inputElement', { static: true }) private inputElement!: ElementRef<HTMLInputElement>;
  @ViewChild(MatAutocompleteTrigger, { static: true }) private matAutoTrigger!: MatAutocompleteTrigger;
  @ViewChild('scrollContainer', { static: false }) private scrollContainer?: ElementRef<HTMLElement>;

  @Input() private algoliaIndex!: string;
  @Input() public defaultOption?: string;
  @Input() private labelField?: string;
  @Input() public optionTemplate?: TemplateRef<HTMLElement>;
  @Input() public additionalOptions: AutocompleteItem[] = [];

  _searchOptions?: SearchOptions;
  @Input() public set searchOptions(options) {
      this._searchOptions = options;
      this.refreshData();
  }

  public get searchOptions() {
    return this._searchOptions;
  }


  @HostBinding('class.app-algolia-autocomplete')
  private readonly parentClass = true;

  @HostBinding() public readonly id = `algolia-autocomplete-${AlgoliaAutocompleteComponent.instanceCount}`;

  @HostBinding('class.floating')
  public get shouldLabelFloat(): boolean {
    return this.focused || !this.empty || !!this.control.value;
  }

  public readonly name = `algolia-autocomplete-${AlgoliaAutocompleteComponent.instanceCount}`;
  public options: AutocompleteItem[] = [];
  public disabled = false;
  public value: any;
  public control = new FormControl();
  public filteredOptions: any[] = [];
  public stateChanges = new Subject<void>();
  public placeholder = '';
  public focused = false;
  public required = false;
  public hasFirstResults = false;
  public loading = false;

  public get empty(): boolean {
    return !this.value;
  }

  private onChange?: (value: any) => void;
  private onTouched?: () => void;
  private term = '';
  private page = 0;
  private hasMoreData = true;
  private panelSubscription?: Subscription;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    @Self() private unsub: AutoUnsubscribeService,
    private fm: FocusMonitor,
    private elRef: ElementRef<HTMLElement>,
    private cdr: ChangeDetectorRef,
    private algolia: AlgoliaService,
    private scroll: ScrollDispatcher,
    private zone: NgZone
  ) {
    AlgoliaAutocompleteComponent.instanceCount++;

    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  public get errorState(): boolean {
    return (this.ngControl.invalid && this.ngControl.touched) || false;
  }

  public ngOnInit(): void {
    this.watchInputChange();
    this.watchInputFocus();
  }

  public ngOnDestroy(): void {
    this.stateChanges.complete();
    this.fm.stopMonitoring(this.elRef);
    this.unwatchPanelScroll();
  }

  public writeValue(value: any): void {
    this.value = value;
    this.control.setValue(value);
    this.stateChanges.next();
    this.cdr.markForCheck();
  }

  public registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }

  public touch(): void {
    if (this.onTouched) {
      this.onTouched();
    }
  }

  public getOptionLabel(option: any): string {
    return (this.labelField ? option?.[this.labelField] : option) || '';
  }

  public setDescribedByIds(): void {
  }

  public onContainerClick(): void {
  }

  public displayFn = (option: any): string => {
    return this.getOptionLabel(option);
  };

  public selectOption(event: MatAutocompleteSelectedEvent): void {
    this.value = event.option.value;
    this.inputElement.nativeElement.blur();

    if (this.onChange) {
      this.onChange(this.value);
    }
  }

  public panelOpened(): void {
    this.watchPanelScroll();
  }

  public panelClosed(): void {
    this.unwatchPanelScroll();
  }

  private watchInputChange(): void {
    const start$ = this.stateChanges.pipe(take(1), mapTo(''));

    const inputChange$ = this.control.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      filter(val => typeof val === 'string')
    );

    this.unsub.subs = merge(start$, inputChange$)
      .pipe(
        tap(term => {
          this.term = (term as string).trim();
          this.page = 0;
        }),
        switchMap(() => this.loadData())
      )
      .subscribe();
  }

  private watchInputFocus(): void {
    this.unsub.subs = this.fm.monitor(this.elRef, true).subscribe(origin => {
      this.focused = !!origin;
      this.stateChanges.next();
      if (!origin) {
        this.refreshInputValue();
      }
    });
  }

  private watchPanelScroll(): void {
    if (!this.scrollContainer) {
      return;
    }

    this.panelSubscription = this.scroll.ancestorScrolled(this.scrollContainer, 300)
      .pipe(
        filter(() => {
          const { clientHeight, scrollHeight, scrollTop } = this.scrollContainer!.nativeElement;
          return clientHeight + scrollTop === scrollHeight;
        }),
        filter(() => this.hasMoreData && !this.loading),
        tap(() => this.page++),
        switchMap(() => this.zone.run(() => this.loadData()))
      )
      .subscribe();
  }

  private refreshInputValue() {
    const inputValue = this.inputElement.nativeElement.value;
    if (this.value && this.labelField && this.value[this.labelField]) {
      this.inputElement.nativeElement.value = inputValue !== this.value[this.labelField] ? this.value[this.labelField] : inputValue;
    }
  }

  private unwatchPanelScroll(): void {
    this.panelSubscription?.unsubscribe();
  }

  private refreshData() {
    this.unsub.subs = this.loadData().subscribe();
  }

  private loadData(): Observable<void> {
    this.loading = true;
    this.cdr.detectChanges();
    return this.algolia.search<AutocompleteItem>(
        this.algoliaIndex,
        this.term,
        { ...this.searchOptions, page: this.page }
      )
      .pipe(
        tap(data => {
          this.options = this.page ? [...this.options, ...data.hits] : [...this.additionalOptions, ...data.hits];
          this.hasMoreData = data.page + 1 < data.nbPages;
          this.hasFirstResults = true;
        }),
        finalize(() => {
          this.loading = false;
          this.cdr.detectChanges();
        }),
        mapTo(void 0)
      );
  }
}
