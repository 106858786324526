import { ApprovalRequest, ApprovalRequestStatus, User } from '@incendi-io/types';
import { Observable, forkJoin, from, of } from 'rxjs';

import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthService } from '../oauth0/oauth0.service';
import { BaseFirebaseService } from './base-firebase.service';
import { Injectable } from '@angular/core';
import { appConstants } from './../../app.constants';

@Injectable({
  providedIn: 'root'
})
export class ApprovalRequestService extends BaseFirebaseService<ApprovalRequest> {
  constructor(auth: AuthService, db: AngularFirestore) {
    super(auth, db, appConstants.firebase.collections.approvalRequest);
  }

  checkGiftsForApproval(user: User): Observable<ApprovalRequest[]> {
    return this.getList([
      ['tenantId', '==', user.tenantId],
      ['status', '==', ApprovalRequestStatus.pending],
      ['approverId', '==', user.id]
    ]);
  }
}
