import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { EmployeeConfirmOptions } from './employee-confirm-options.interface';

@Component({
  selector: 'app-employee-confirm',
  templateUrl: './employee-confirm.component.html',
  styleUrls: ['./employee-confirm.component.scss']
})
export class EmployeeConfirmComponent {
  public options!: EmployeeConfirmOptions;

  constructor(private activeModal: NgbActiveModal) { }

  public close(): void {
    this.activeModal.dismiss();
  }

  public confirmAction(): void {
    this.activeModal.close(true);
  }
}
