import {
  AngularFirestore,
  CollectionReference,
  DocumentChangeAction,
  DocumentReference,
  Query
} from '@angular/fire/compat/firestore';
import { Observable, forkJoin, from, of, throwError } from 'rxjs';

import { AuthService } from '../oauth0/oauth0.service';
import { CommonFirestore } from '@incendi-io/types';
import { StateOperation } from '../../interfaces/state-operation.interface';
import { default as _chunk } from 'lodash-es/chunk';
import { appConstants } from '../../app.constants';
import firebase from 'firebase/compat/app';
import { map } from 'rxjs/operators';
import { serverTimestamp } from 'firebase/firestore';

export type CustomQuery = [string, firebase.firestore.WhereFilterOp, any];

export class BaseFirebaseService<T extends CommonFirestore = any> {
  constructor(protected auth: AuthService, protected db: AngularFirestore, protected collectionName: string) {}

  public batchUpdate<T extends { id: string }>(data: Partial<T>[], merge = false): Observable<void[]> {
    const chunks = _chunk<Partial<T>>(data, appConstants.firebase.maxBatchSize);
    const collectionRef = this.db.firestore.collection(this.collectionName);
    const requests: Observable<void>[] = [];
    chunks.forEach(chunk => {
      const batch = this.db.firestore.batch();
      chunk.forEach(doc => {
        const { id, ...rest } = doc;
        const copy = {
          ...rest,
          updatedAt: serverTimestamp(),
          updatedBy: this.auth.profile!.userId
        };
        const docRef = id ? collectionRef.doc(id) : collectionRef.doc();
        batch.set(docRef, copy, { merge });
      });
      requests.push(from(batch.commit()));
    });

    if (!requests.length) {
      return of([]);
    }

    return forkJoin(requests);
  }

  public getList(customQuery?: CustomQuery[]): Observable<T[]> {
    return this.db
      .collection<T>(this.collectionName, ref => {
        let query = this.queryFunction(ref);
        (customQuery || []).forEach(([field, operation, value]) => {
          query = query.where(field, operation, value);
        });
        return query;
      })
      .get()
      .pipe(
        map(querySnapshot => {
          return querySnapshot.empty ? [] : querySnapshot.docs.map(doc => this.mapFunction(doc));
        })
      );
  }

  public getLiveList$(
    customQuery?: CustomQuery[],
    order?: string,
    direction?: firebase.firestore.OrderByDirection
  ): Observable<T[]> {
    return this.db
      .collection<T>(this.collectionName, ref => {
        let query = this.queryFunction(ref);
        (customQuery || []).forEach(([field, operation, value]) => {
          query = query.where(field, operation, value);
        });

        if (order) {
          query = query.orderBy(order, direction);
        }

        return query;
      })
      .snapshotChanges()
      .pipe(
        map(documentChangeAction => {
          return documentChangeAction.map(docChange => this.mapFunction(docChange.payload.doc));
        })
      );
  }

  public getLiveStateList$(customQuery?: CustomQuery[]): Observable<StateOperation<T>[]> {
    return this.db
      .collection<T>(this.collectionName, ref => {
        let query = this.queryFunction(ref);
        (customQuery || []).forEach(([field, operation, value]) => {
          query = query.where(field, operation, value);
        });
        return query;
      })
      .stateChanges()
      .pipe(map(changes => changes.map(doc => this.mapStateFunction(doc))));
  }

  protected mapStateFunction(change: DocumentChangeAction<T>): StateOperation<T> {
    return {
      object: {
        ...(this.mapTimestamps(change.payload.doc.data()) as T),
        id: change.payload.doc.id
      },
      operation: change.type
    };
  }

  public get(docId: string): Observable<T> {
    return this.db
      .collection<T>(this.collectionName)
      .doc<T>(docId)
      .get()
      .pipe(map(documentSnapshot => this.mapFunction(documentSnapshot)));
  }

  public create(data: T): Observable<DocumentReference> {
    const timestamp = new Date();
    const copy = {
      ...data,
      updatedAt: timestamp,
      createdAt: timestamp,
      createdBy: this.auth.profile!.userId,
      updatedBy: this.auth.profile!.userId
    };
    delete copy.id;
    copy.tenantId = this.auth.profile!.tenantId;
    try {
      return from(this.db.collection<T>(this.collectionName).add(copy));
    } catch (e) {
      return throwError(e);
    }
  }

  public update(docId: string, data: Partial<T>, merge = false): Observable<void> {
    const timestamp = new Date();
    const copy = {
      ...data,
      updatedAt: timestamp,
      updatedBy: this.auth.profile!.userId
    };
    delete copy.id;
    copy.tenantId = this.auth.profile!.tenantId;
    try {
      return from(this.db.collection<Partial<T>>(this.collectionName).doc<Partial<T>>(docId).set(copy, { merge }));
    } catch (e) {
      return throwError(e);
    }
  }

  public delete(docId: string): Observable<void> {
    return from(this.db.collection(this.collectionName).doc(docId).delete());
  }

  protected queryFunction(ref: CollectionReference): Query {
    return ref.where('tenantId', '==', this.auth.profile!.tenantId);
  }

  protected mapFunction(doc: firebase.firestore.DocumentSnapshot): T {
    return {
      ...(doc.data() as T),
      id: doc.id
    };
  }

  protected translateTimeStamps(docs: T[]): T[] {
    return docs.map(doc => {
      return {
        ...(this.mapTimestamps(doc) as T)
      };
    });
  }

  protected mapTimestamps(obj?: { [key: string]: any }): any {
    if (!obj) {
      return;
    }

    Object.keys(obj)
      .filter(key => !!(obj[key] as firebase.firestore.Timestamp)?.toDate)
      .forEach(key => (obj[key] = (obj[key] as firebase.firestore.Timestamp).toDate()));

    return obj;
  }
}
