import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { ApiService } from './api.service';
import { CoreService } from './core.service';
import { TokenInterceptor } from './interceptors/token.interceptor';

@NgModule({
  imports: [
    HttpClientModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    ApiService,
    CoreService
  ]
})
export class ApiModule {
}
