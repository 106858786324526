import { Component, Self } from '@angular/core';
import { Router } from '@angular/router';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { switchMap } from 'rxjs/operators';

import { AuthService } from '../../core/oauth0/oauth0.service';
import { AutoUnsubscribeService } from '../../core/services/auto-unsubscribe.service';
import { ROUTER_LINKS } from '../../interfaces/router-links.enum';

@Component({
  selector: 'app-profile-header',
  templateUrl: './profile-header.component.html',
  styleUrls: ['./profile-header.component.scss'],
  providers: [AutoUnsubscribeService]
})
export class ProfileHeaderComponent {
  public readonly user$ = this.auth.getLiveProfile$();

  constructor(
    @Self() private readonly unsub: AutoUnsubscribeService,
    private readonly auth: AuthService,
    private readonly router: Router,
    private readonly config: NgbDropdownConfig
  ) {
    config.placement = ['bottom-left', 'top-right'];
  }

  public logout(): void {
    this.unsub.subs = this.auth.logout()
      .pipe(switchMap(() => this.router.navigate([ROUTER_LINKS.LOGOUT])))
      .subscribe();
  }
}
