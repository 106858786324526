import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Report } from '@incendi-io/types';
import { ContentfulCollection, Entry } from 'contentful';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { ContentfulPreviewMode } from '../../interfaces/contentful-preview-mode.enum';

export interface PowerBITokenResponse {
  token: string;
  tokenId: string;
}

export interface UserResponse {
  userName: string;
  password: string;
}

export interface PowerBICreds {
  authMode?: string;
  clientId?: string;
  clientSecret?: string;
  password?: string;
  pbiTenantId?: string;
  userName?: string;
  workSpaceId?: string;
}

@Injectable()
export class CoreService {
  private static readonly url = `${environment.api}/core`;

  constructor(private http: HttpClient) {
  }

  public getFireBaseToken(): Observable<string> {
    return this.http.get<{ token: string }>(`${CoreService.url}/token`)
      .pipe(map(res => res.token));
  }

  public getAlgoliaToken(): Observable<string> {
    return this.http.get<{ token: string }>(`${CoreService.url}/search-token`)
      .pipe(map(res => res.token));
  }

  public getDBConnectionString(): Observable<string> {
    return this.http.get<{ connString: string }>(`${CoreService.url}/settings/get-db-connection`)
      .pipe(map(res => res.connString));
  }

  public getAPICredentials(): Observable<UserResponse> {
    return this.http.get<UserResponse>(`${CoreService.url}/settings/get-api-creds`);
  }

  public resetPassword(password: string) {
    return this.http.post(
      `${CoreService.url}/settings/reset-password`,
      { password }
    );
  }

  public inviteUser(userId: string): Observable<void> {
    return this.http.get<void>(`${CoreService.url}/invite/${userId}`);
  }

  public getPowerBICreds(): Observable<PowerBICreds> {
    return this.http.post<PowerBICreds>(
      `${CoreService.url}/settings/pbi/getPowerBICreds`,
      null
    );
  }

  public setPowerBICreds(creds: PowerBICreds) {
    return this.http.post(
      `${CoreService.url}/settings/pbi/setPowerBICreds`,
      creds
    );
  }

  public getPowerBIReports(): Observable<Report[]> {
    return this.http.post<Report[]>(
      `${CoreService.url}/settings/pbi/getPowerBIReports`,
      null
    );
  }

  public getPowerBIReport(report: Report): Observable<PowerBITokenResponse> {
    return this.http.post<PowerBITokenResponse>(
      `${CoreService.url}/settings/pbi/getPowerBIEmbeddedReport`,
      report
    );
  }

  public getContentfulItems<T = any>(
    query: any,
    previewMode: ContentfulPreviewMode = ContentfulPreviewMode.cdn
  ): Observable<ContentfulCollection<Entry<T>>> {
    return this.http.get<ContentfulCollection<Entry<T>>>(
      `${environment.contentfulApiUrl}/contentDelivery/${previewMode}`,
      {
        params: query
      }
    );
  }

  public getContentfulItem<T = any>(
    id: string,
    query: any,
    previewMode: ContentfulPreviewMode = ContentfulPreviewMode.cdn
  ): Observable<Entry<T>> {
    return this.http.get<Entry<T>>(
      `${environment.contentfulApiUrl}/contentDelivery/${previewMode}/${id}`,
      {
        params: query
      }
    );
  }
}
