import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Tenant } from '@incendi-io/types';
import { Observable } from 'rxjs';

import { appConstants } from '../../app.constants';
import { AuthService } from '../oauth0/oauth0.service';
import { BaseFirebaseService } from './base-firebase.service';

@Injectable({
  providedIn: 'root'
})
export class TenantsService extends BaseFirebaseService<Tenant> {
  constructor(
    auth: AuthService,
    db: AngularFirestore
  ) {
    super(auth, db, appConstants.firebase.collections.tenants);
  }

  public getCurrent(): Observable<Tenant> {
    return this.get(this.auth.profile!.tenantId!);
  }
}
