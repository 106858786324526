import { Injectable } from '@angular/core';
import { User, UserFormData, UserSearch, UserStatus } from '@incendi-io/types';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { appConstants } from '../../app.constants';
import { countriesList } from '../../core/countries.constant';
import { AlgoliaService } from '../../core/services/algolia.service';
import { languages } from '../../transloco/languages.constant';

@Injectable()
export class UserModelService {
  static parseUserModel(userModel: User & { [key: string]: any }, users?: UserSearch[]): UserFormData {
    const parsedModel = {} as UserFormData;
    userModel.delegateId = userModel.delegateId || '';

    if (userModel.areaId && userModel.area) {
      parsedModel.area = {
        id: userModel.areaId,
        name: userModel.area
      };
    }

    if (userModel.country) {
      const country = countriesList.find(c => c.name === userModel.country);
      parsedModel.country = country || { name: userModel.country, code: '' };
    }

    if (userModel.currency && userModel.currencyId) {
      parsedModel.currency = {
        currencyCode: userModel.currency,
        description: '',
        id: userModel.currencyId
      };
    }

    const delegate = (users || []).find(u => u.firestoreId === userModel.delegateId);
    if (delegate) {
      parsedModel.delegate = delegate;
    }

    if (userModel.division && userModel.divisionId) {
      parsedModel.division = {
        id: userModel.divisionId,
        name: userModel.division
      };
    }

    if (userModel.entity && userModel.entityId) {
      parsedModel.entity = {
        id: userModel.entityId,
        name: userModel.entity
      };
    }

    if (userModel.managerId) {
      const manager = (users || []).find(u => u.userId === userModel.managerId);
      if (manager) {
        parsedModel.manager = manager;
      }
    }

    if (userModel.role && userModel.roleId) {
      parsedModel.role = {
        description: '',
        id: userModel.roleId,
        name: userModel.role
      };
    }

    const currentLanguage = languages.find(lang => lang.name === userModel.accountLanguage);

    return {
      ...parsedModel,
      accountLanguage: currentLanguage || languages[0],
      email: userModel.email as string,
      firstName: userModel.firstName,
      lastName: userModel.lastName,
      phone: userModel.phone || ''
    };
  }

  static prepareUserModel(
    userFormData: UserFormData,
    userModelData: Partial<User>,
    initialStatus: UserStatus
  ): Partial<User> {
    const userModel: Partial<User> = {
      accountLanguage: userFormData.accountLanguage.name,
      accountLanguageId: userFormData.accountLanguage.id,
      currency: userFormData.currency.currencyCode,
      currencyId: userFormData.currency.id,
      delegateId: userFormData.delegate ? userFormData.delegate.firestoreId : '',
      email: userFormData.email.toLowerCase().trim(),
      firstLogin: userModelData.firstLogin ?? false,
      firstName: userFormData.firstName.trim(),
      fullName: `${userFormData.firstName.trim()} ${userFormData.lastName.trim()}`.trim(),
      language: userModelData.language || 'en-us',
      lastName: userFormData.lastName.trim(),
      managerId: userFormData.manager ? userFormData.manager.userId : '',
      managerFullName: userFormData.manager ? userFormData.manager.fullName : '',
      status: initialStatus
    };

    if (userModelData.userId) {
      userModel.userId = userModelData.userId;
    }

    if (userFormData.area) {
      userModel.area = userFormData.area.name;
      userModel.areaId = userFormData.area.id;
    }

    if (userFormData.country) {
      userModel.country = userFormData.country.name;
    }

    if (userFormData.division) {
      userModel.division = userFormData.division.name;
      userModel.divisionId = userFormData.division.id;
    }

    if (userFormData.entity) {
      userModel.entity = userFormData.entity.name;
      userModel.entityId = userFormData.entity.id;
    }

    if (userFormData.phone) {
      userModel.phone = userFormData.phone;
    }

    if (userFormData.role) {
      userModel.role = userFormData.role.name;
      userModel.roleId = userFormData.role.id;
    }

    return userModel;
  }

  constructor(private algolia: AlgoliaService) {}

  public propagateUsers(user: User): Observable<UserSearch[]> {
    const filters: string[] = [];

    if (user.managerId) {
      filters.push(`userId:${user.managerId}`);
    }

    if (user.delegateId) {
      filters.push(`firestoreId:${user.delegateId}`);
    }

    if (!filters.length) {
      return of([]);
    }

    return this.algolia
      .search<UserSearch>(appConstants.algolia.indices.users, '', { filters: filters.join(' OR ') })
      .pipe(map(res => res.hits));
  }
}
