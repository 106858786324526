export const appConstants = {
  algolia: {
    defaultPageSize: 10,
    indices: {
      approvalRequest: 'yourtrust_approvalRequests',
      areas: 'intrust_areas',
      currencies: 'core_currencies',
      divisions: 'intrust_divisions',
      entities: 'yourtrust_entities',
      gifts: 'intrust_gifts',
      locations: 'core_locations',
      positions: 'core_positions',
      roles: 'core_roles',
      teams: 'core_teams',
      users: 'auth_users',
      usersFullNameAsc: 'auth_users_full_name_asc'
    }
  },
  cookies: {
    appToken: 'appToken',
    expiresHours: 10,
    firebaseToken: 'firebaseToken',
    searchToken: 'searchToken'
  },
  firebase: {
    collections: {
      algoliaQueue: 'common_algolia_queue',
      approvalRequest: 'yourtrust_approvalRequests',
      areas: 'intrust_areas',
      currencies: 'core_currencies',
      divisions: 'intrust_divisions',
      entities: 'yourtrust_entities',
      gifts: 'intrust_gifts',
      locations: 'core_locations',
      positions: 'core_positions',
      roles: 'core_roles',
      teams: 'core_teams',
      tenants: 'auth_tenants',
      users: 'auth_users',
      userTotals: 'intrust_totals'
    },
    maxBatchSize: 500,
    maxComparisonSize: 10
  }
};
