import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';

import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { TranslocoModule } from '@ngneat/transloco';
import { ModalHeaderModule } from '../modal-header/modal-header.module';
import { ConfirmComponent } from './confirm.component';
import { ConfirmService } from './confirm.service';

@NgModule({
  declarations: [ConfirmComponent],
  imports: [CommonModule, NgbModalModule, ReactiveFormsModule, MatInputModule, ModalHeaderModule, TranslocoModule],
  providers: [ConfirmService]
})
export class ConfirmModule {}
