import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdministratorGuard } from './core/administrator.guard';

import { AuthGuard } from './core/auth.guard';
import { ROUTER_LINKS } from './interfaces/router-links.enum';
import { LogoutComponent } from './logout/logout.component';

const routes: Routes = [
  {
    path: ROUTER_LINKS.STAFF,
    loadChildren: () => import('./staff/staff.module').then(m => m.StaffModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard, AdministratorGuard]
  },
  {
    path: ROUTER_LINKS.SETTINGS,
    loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard]
  },
  {
    path: ROUTER_LINKS.HOME,
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard]
  },
  {
    path: ROUTER_LINKS.ONBOARDING,
    loadChildren: () => import('./onboarding/onboarding.module').then(m => m.OnboardingModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard]
  },
  {
    path: ROUTER_LINKS.TEAM,
    loadChildren: () => import('./team/team.module').then(m => m.TeamModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard]
  },
  {
    path: ROUTER_LINKS.REGISTRY_REVIEW,
    loadChildren: () => import('./review/review.module').then(m => m.ReviewModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard]
  },
  {
    path: ROUTER_LINKS.ANALYTICS,
    loadChildren: () => import('./analytics/analytics.module').then(m => m.AnalyticsModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard, AdministratorGuard]
  },
  {
    path: ROUTER_LINKS.PREVIEW,
    loadChildren: () => import('./preview-content/preview-content.module').then(m => m.PreviewContentModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard, AdministratorGuard]
  },
  {
    path: ROUTER_LINKS.DEACTIVATED,
    loadChildren: () => import('./deactivated-screen/deactivated-screen.module').then(m => m.DeactivatedScreenModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard]
  },
  {
    path: ROUTER_LINKS.HOME_CONTENT,
    loadChildren: () => import('./news/news.module').then(m => m.NewsModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    data: {
      title: 'home',
      rootLink: ROUTER_LINKS.HOME,
      rootPath: ROUTER_LINKS.HOME_CONTENT,
      releaseToPublic: true
    }
  },
  {
    path: ROUTER_LINKS.PREVIEW_CONTENT,
    loadChildren: () => import('./news/news.module').then(m => m.NewsModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard, AdministratorGuard],
    data: {
      title: 'previewContent',
      rootLink: ROUTER_LINKS.PREVIEW,
      rootPath: ROUTER_LINKS.PREVIEW_CONTENT,
      releaseToPublic: false
    }
  },
  {
    path: ROUTER_LINKS.LOGOUT,
    component: LogoutComponent
  },
  {
    path: '**',
    redirectTo: ROUTER_LINKS.HOME
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
