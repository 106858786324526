import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { UserStatuses } from '@incendi-io/types';
import { Observable } from 'rxjs';
import { mapTo } from 'rxjs/operators';

import { ROUTER_LINKS } from '../interfaces/router-links.enum';
import { AuthService } from './oauth0/oauth0.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad {
  constructor(
    private auth: AuthService,
    private router: Router
  ) {
  }

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean> {
    return this.check(state.url || '');
  }

  public canLoad(route: Route): boolean | UrlTree | Observable<boolean> {
    return this.check(route.path || '');
  }

  private check(path: string): boolean | UrlTree | Observable<boolean> {
    if (this.auth.profile) {
      if (!this.auth.profile.firstLogin && !path.includes(ROUTER_LINKS.ONBOARDING)) {
        return this.router.createUrlTree([ROUTER_LINKS.ONBOARDING]);
      }

      if (this.auth.profile.firstLogin && path.includes(ROUTER_LINKS.ONBOARDING)) {
        return this.router.createUrlTree([ROUTER_LINKS.HOME]);
      }

      if (this.auth.profile.status === UserStatuses.deactivated && !path.includes(ROUTER_LINKS.DEACTIVATED)) {
        return this.router.createUrlTree([ROUTER_LINKS.DEACTIVATED]);
      }

      if (this.auth.profile.status !== UserStatuses.deactivated && path.includes(ROUTER_LINKS.DEACTIVATED)) {
        return this.router.createUrlTree([ROUTER_LINKS.HOME]);
      }

      return true;
    } else {
      return this.auth.login().pipe(mapTo(false));
    }
  }
}
