import { FormControl, FormGroup, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

export function triggerValidation(form: FormGroup): void {
  Object.keys(form.controls).forEach(key => form.controls[key].updateValueAndValidity());
}

export class FormSubmitErrorStateMatcher implements ErrorStateMatcher {
  public isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return !!(form?.submitted && control?.invalid);
  }
}
