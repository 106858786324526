import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RouterModule } from '@angular/router';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoModule } from '@ngneat/transloco';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { UiSwitchModule } from 'ngx-ui-switch';

import { AlgoliaAutocompleteModule } from './algolia-autocomplete/algolia-autocomplete.module';
import { AnnouncementCardComponent } from './announcement/announcement-card/announcement-card.component';
import { AutoBlurDirective } from './auto-blur.directive';
import { AutocompleteModule } from './autocomplete/autocomplete.module';
import { ControlMessagesComponent } from './control-messages/control-messages.component';
import { EmployeeConfirmComponent } from './employee-confirm/employee-confirm.component';
import { EmployeeConfirmService } from './employee-confirm/employee-confirm.service';
import { EmployeeDetailsComponent } from './employee-details/employee-details.component';
import { EmployeeDetailsService } from './employee-details/employee-details.service';
import { UserModelService } from './employee-details/user-model.service';
import { DropDownComponent } from './form-controls/drop-down/drop-down.component';
import { ModalHeaderModule } from './modal-header/modal-header.module';
import { NewsCardComponent } from './news-card/news-card.component';
import { IncendiPipesModule } from './pipes/incendi-pipes.module';
import { SnackBarComponent } from './snack-bar/snack-bar.component';
import { TableStateWatermarkComponent } from './table/table-state-watermark/table-state-watermark.component';
import { UserAvatarComponent } from './user-avatar/user-avatar.component';

const components = [
  AutoBlurDirective,
  ControlMessagesComponent,
  DropDownComponent,
  EmployeeConfirmComponent,
  EmployeeDetailsComponent,
  SnackBarComponent,
  TableStateWatermarkComponent,
  UserAvatarComponent,
  AnnouncementCardComponent,
  NewsCardComponent
];

const modules = [
  MatFormFieldModule,
  MatInputModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatSnackBarModule,
  NgbDropdownModule,
  PerfectScrollbarModule,
  ReactiveFormsModule,
  RouterModule,
  TranslocoModule,
  UiSwitchModule,

  AlgoliaAutocompleteModule,
  AutocompleteModule,
  IncendiPipesModule,
  ModalHeaderModule
];

const providers = [
  EmployeeConfirmService,
  EmployeeDetailsService,
  UserModelService
];

@NgModule({
  imports: [
    CommonModule,
    ...modules
  ],
  declarations: [
    ...components
  ],
  providers: [
    ...providers
  ],
  exports: [
    ...components,
    ...modules
  ]
})
export class SharedModule {}
