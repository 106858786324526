import { Injectable } from '@angular/core';
import { documentToHtmlString, Options } from '@contentful/rich-text-html-renderer';
import { TranslocoService } from '@ngneat/transloco';
import { Entry } from 'contentful';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ContentfulQuery } from '../../interfaces/contentfull.interface';
import { languages } from '../../transloco/languages.constant';
import { ApiService } from '../api/api.service';

export interface AnnouncementType {
  icon: string;
  title: string;
}

export interface AnnouncementAttachments {
  name: string;
  url: string;
}

export interface AnnouncementCard {
  attachments?: AnnouncementAttachments[];
  author?: string;
  body?: string;
  date: string;
  id: string;
  title: string;
  type?: AnnouncementType;
}

export interface NewsCardInfo {
  author: string;
  body?: string;
  date: string;
  id: string;
  image: string;
  title: string;
}

export interface NewsCategory {
  id: string;
  title: string;
}

const msInDay = 24 * 60 * 60 * 1000;

@Injectable({
  providedIn: 'root'
})
export class ContentfulService {
  private static contentfulParserOptions: Options = {
    renderNode: {
      'embedded-asset-block': (node) => {
        const contentType: string = node.data?.target?.fields?.file?.contentType || '';
        const url = node.data?.target?.fields?.file?.url || '';
        const fileName = node.data?.target?.fields?.file?.fileName || '';

        if (contentType.includes('image')) {
          return `<img src="${url}" alt="${fileName}">`;
        }

        if (contentType.includes('video')) {
          return `<video src="${url}" controls></video>`;
        }

        if (url) {
          return `<div class="attachment">
            <div class="attachment-icon">
              <svg>
                <use xlink:href="#attachment-file"></use>
              </svg>
            </div>

            <a href="${url}" class="filename text-truncate" target="_blank">${fileName}</a>
         </div>`;
        }

        return '';
      }
    }
  };

  constructor(
    private translate: TranslocoService,
    private api: ApiService
  ) {
  }

  public static isAnnouncementRecent(announcement: AnnouncementCard): boolean {
    const newsDate = new Date(announcement.date).getTime();
    const now = Date.now();
    return (now - newsDate) < (2 * msInDay); // Less then 2 days
  }

  public getNewsCard(id: string): Observable<NewsCardInfo> {
    return this.getItem<any>(id)
      .pipe(map(res => {
        return {
          author: res.fields?.author?.fields?.name || '',
          body: documentToHtmlString(res.fields.content, ContentfulService.contentfulParserOptions),
          date: res.sys.createdAt,
          id: res.sys.id,
          image: res.fields.image?.fields?.file?.url || '',
          title: res.fields.title
        };
      }));
  }

  public getNewsCards(query: ContentfulQuery = {}, releaseToPublic = true): Observable<NewsCardInfo[]> {
    const newsCardQuery: ContentfulQuery = {
      locale: this.getLocale(),
      content_type: 'news',
      order: '-sys.updatedAt',
      ['fields.releaseToPublic']: releaseToPublic,
      select: 'sys.id,sys.updatedAt,fields.title,fields.thumbnail,fields.image,fields.author,fields.releaseToPublic'
    };
    return this.getDataList<any>({ ...newsCardQuery, ...query })
      .pipe(
        map(res => res.map(item => {
          return {
            author: item.fields.author?.fields?.name || '',
            date: item.sys.updatedAt,
            id: item.sys.id,
            image: item.fields.thumbnail ? item.fields.thumbnail.fields.file.url : item.fields.image.fields.file.url,
            title: item.fields.title
          };
        }))
      );
  }

  public getNewsCategories(query: ContentfulQuery = {}): Observable<NewsCategory[]> {
    const newsCardQuery: ContentfulQuery = {
      content_type: 'newsCategories',
      order: 'fields.order',
      select: 'sys.id,fields.title',
      'fields.showOnDashboard': true,
      locale: this.getLocale()
    };
    return this.getDataList<any>({ ...newsCardQuery, ...query })
      .pipe(
        map((res: Entry<any>[]) => res.map((item: Entry<any>) => {
          return {
            id: item.sys.id,
            title: item.fields.title
          };
        }))
      );
  }

  public getAnnouncementCard(id: string): Observable<AnnouncementCard> {
    return this.getItem<any>(id)
      .pipe(map(res => {
        const attachments: AnnouncementAttachments[] = (res.fields.attachment || []).map((attachment: any) => {
          return {
            name: attachment.fields.file.fileName,
            url: attachment.fields.file.url
          };
        });
        return {
          attachments,
          author: res.fields?.author?.fields?.name || '',
          body: documentToHtmlString(res.fields.content, ContentfulService.contentfulParserOptions),
          date: res.sys.createdAt,
          id: res.sys.id,
          title: res.fields.title
        };
      }));
  }

  public getAnnouncementCards(query: ContentfulQuery = {}, releaseToPublic = true): Observable<AnnouncementCard[]> {
    const announcementCardQuery: ContentfulQuery = {
      content_type: 'announcement',
      order: '-sys.updatedAt',
      ['fields.releaseToPublic']: releaseToPublic,
      select: 'sys.id,sys.updatedAt,fields.title,fields.releaseToPublic',
      locale: this.getLocale()
    };
    return this.getDataList({ ...announcementCardQuery, ...query })
      .pipe(
        map(res => res.map((item: Entry<any>) => {
          const announcement: AnnouncementCard = {
            id: item.sys.id,
            title: item.fields.title,
            date: item.sys.updatedAt
          };

          if (item.fields.type) {
            announcement.type = {
              icon: item.fields.type.fields.icon.fields.file.url,
              title: item.fields.type.fields.title
            };
          }

          return announcement;
        }))
      );
  }

  private getDataList<T>(query: ContentfulQuery): Observable<Entry<T>[]> {
    return this.api.core.getContentfulItems<T>(query)
      .pipe(map(res => res.items));
  }

  private getItem<T>(id: string): Observable<Entry<T>> {
    return this.api.core.getContentfulItem<T>(id, { locale: this.getLocale() });
  }

  private getLocale(): string {
    switch (this.translate.getActiveLang()) {
      case languages[0].id:
        return 'en-US';
      case languages[1].id:
        return languages[1].id;
      default:
        return 'en-US';
    }
  }
}
