import { SearchOptions } from '@algolia/client-search';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  OnInit,
  Self
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  Area,
  CommonFirestore,
  Currency,
  Division,
  Entity,
  Role,
  User,
  UserSearch,
  UserStatuses
} from '@incendi-io/types';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService, translate } from '@ngneat/transloco';
import firebase from 'firebase/compat/app';
import { Observable, forkJoin, of } from 'rxjs';
import { filter, map, mapTo, switchMap, tap } from 'rxjs/operators';

import { appConstants } from '../../app.constants';
import { countriesList } from '../../core/countries.constant';
import { AuthService } from '../../core/oauth0/oauth0.service';
import { AreaService } from '../../core/services/area.service';
import { AutoUnsubscribeService } from '../../core/services/auto-unsubscribe.service';
import { CurrencyService } from '../../core/services/currency.service';
import { DivisionService } from '../../core/services/division.service';
import { EntityService } from '../../core/services/entity.service';
import { RolesService } from '../../core/services/role.service';
import { UsersGlobalService } from '../../core/services/users-global.service';
import { UsersService } from '../../core/services/users.service';
import { triggerValidation } from '../../core/utils/forms';
import { languages } from '../../transloco/languages.constant';
import { SnackBarService } from '../snack-bar/snack-bar.service';
import { EmployeeDetailsOptions } from './employee-details.interfce';
import { emailRegExp, phoneRegExp } from './regex.constant';
import { UserModelService } from './user-model.service';

enum Steps {
  checkEmail,
  checking,
  userExists,
  inviteUser,
  inviting,
  saveUser,
  saving,
  transferCheck,
  transferConfirm,
  transferUserAlreadyInTeam,
  transferring,
  transferInvalid
}

type Mutable<T> = { -readonly [Property in keyof T]: T[Property] };

@Component({
  templateUrl: './employee-details.component.html',
  styleUrls: ['./employee-details.component.scss'],
  providers: [AutoUnsubscribeService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmployeeDetailsComponent implements OnInit {
  @HostBinding('class.user-exists')
  private get hostClass(): boolean {
    return !!this.existingUser;
  }

  public readonly usersIndex = appConstants.algolia.indices.usersFullNameAsc;
  public usersSearchOptions: Mutable<SearchOptions> = { filters: `status:${UserStatuses.active}` };
  public areas: Area[] = [];
  public countries = countriesList;
  public currencies: Currency[] = [];
  public divisions: Division[] = [];
  public form!: FormGroup;
  public options!: EmployeeDetailsOptions;
  public preLoader = true;
  public roles: Role[] = [];
  public entities: Entity[] = [];
  public existingUser: User | null = null;
  public hideCorporateInfo = !this.auth.tenant?.enterprise;
  public readonly languages = languages;
  public isActiveUser = false;
  public noneUser = {
    firestoreId: '',
    fullName: translate<string>('noneOption'),
    email: ''
  } as UserSearch;

  public get buttonLabel(): string {
    switch (this.currentStep) {
      case Steps.checkEmail:
        return 'checkEmailAndContinue';
      case Steps.checking:
        return 'checking';
      case Steps.inviteUser:
        return 'inviteUser';
      case Steps.inviting:
        return 'inviting';
      case Steps.userExists:
        return 'okay';
      case Steps.saving:
        return 'saving';
      case Steps.transferConfirm:
        return 'yes';
      case Steps.transferUserAlreadyInTeam:
        return 'okay';
      case Steps.transferring:
        return 'userTransfering';
      case Steps.transferInvalid:
        return 'okay';
      default:
        return 'save';
    }
  }

  public get transferModeInfoLabel(): string {
    switch (this.currentStep) {
      case Steps.transferConfirm:
      case Steps.transferring:
        return 'userTransferConfirm';
      case Steps.transferUserAlreadyInTeam:
        return 'userTransferAlreadyInTeamInfo';
      case Steps.transferInvalid:
        return 'userTransferInvalid';
      default:
        return 'info';
    }
  }

  public get buttonClass(): string {
    switch (this.currentStep) {
      case Steps.checkEmail:
      case Steps.checking:
        return 'check-button';
      default:
        return '';
    }
  }

  public get processing(): boolean {
    return (
      this.currentStep === Steps.checking ||
      this.currentStep === Steps.inviting ||
      this.currentStep === Steps.saving ||
      this.currentStep === Steps.transferring
    );
  }

  public get editMode(): boolean {
    return (
      this.currentStep === Steps.inviteUser ||
      this.currentStep === Steps.saveUser ||
      this.currentStep === Steps.inviting ||
      this.currentStep === Steps.saving
    );
  }

  public get transferMode(): boolean {
    return (
      this.currentStep === Steps.transferConfirm ||
      this.currentStep === Steps.transferCheck ||
      this.currentStep === Steps.transferUserAlreadyInTeam ||
      this.currentStep === Steps.transferring ||
      this.currentStep === Steps.transferInvalid
    );
  }

  public get email(): string {
    return (((this.form.get('email') as FormControl).value as string) || '').trim().toLowerCase();
  }

  public get allSteps() {
    return Steps;
  }

  public currentStep = Steps.checkEmail;
  private initialStatus = UserStatuses.invited;
  private userModel: User | undefined;

  constructor(
    @Self() private unsub: AutoUnsubscribeService,
    private translateService: TranslocoService,
    private activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private auth: AuthService,
    private currencyService: CurrencyService,
    private divisionService: DivisionService,
    private rolesService: RolesService,
    private entityService: EntityService,
    private areaService: AreaService,
    private usersService: UsersService,
    private usersGlobalService: UsersGlobalService,
    private elementRef: ElementRef<HTMLElement>,
    private snackBar: SnackBarService,
    private userModelService: UserModelService
  ) {}

  public ngOnInit(): void {
    this.createForm();
    this.getCurrenciesList();
    this.getDivisionsList();
    this.getAreasList();
    this.getRolesList();
    this.getEntitiesList();

    if (!this.options.docId) {
      this.preLoader = false;
      this.cdr.markForCheck();
    } else {
      this.unsub.subs = this.usersService
        .get(this.options.docId)
        .pipe(
          switchMap(currentUser => {
            this.userModel = currentUser;
            this.usersSearchOptions.filters += ` AND NOT firestoreId:${currentUser.id}`;
            this.initialStatus = currentUser.status;
            this.currentStep = Steps.saveUser;
            this.isActiveUser = currentUser.status === UserStatuses.active;
            return this.userModelService.propagateUsers(currentUser);
          }),
          tap(users =>
            this.form.patchValue(UserModelService.parseUserModel(this.userModel as User, users.concat(this.noneUser)))
          ),
          switchMap(() => this.currencyService.get(this.userModel!.currencyId!)),
          tap(currency => {
            this.form.get('currency')!.setValue({
              id: currency.id,
              currencyCode: currency.currencyCode,
              description: currency.description
            });
            this.cdr.markForCheck();
          })
        )
        .subscribe(() => {
          this.preLoader = false;
          this.cdr.markForCheck();
        });
    }

    if (this.options.managerInvite) {
      this.form.patchValue({
        accountLanguage: {
          id: this.auth.profile?.accountLanguageId,
          name: this.auth.profile?.accountLanguage
        },
        area: {
          id: this.auth.profile?.areaId,
          name: this.auth.profile?.area
        },
        country: {
          name: this.auth.profile?.country
        },
        division: {
          id: this.auth.profile?.divisionId,
          name: this.auth.profile?.division
        },
        entity: {
          id: this.auth.profile?.entityId,
          name: this.auth.profile?.entity
        },
        manager: this.auth.profile
      });

      if (this.auth.profile?.currencyId) {
        this.unsub.subs = this.currencyService.get(this.auth.profile.currencyId).subscribe(res => {
          this.form.get('currency')!.setValue({
            id: res.id,
            currencyCode: res.currencyCode,
            description: res.description
          });
          this.cdr.markForCheck();
        });
      }

      if (this.auth.profile?.managerId && !this.form.get('entity')!.value) {
        this.unsub.subs = this.usersService
          .getUserByUserId(this.auth.profile.managerId)
          .pipe(filter(user => !!user?.entityId))
          .subscribe(user => {
            this.form.get('entity')!.setValue({
              id: user!.entityId,
              name: user!.entity
            });
            this.cdr.markForCheck();
          });
      }
    }
  }

  public compareFunction(item1: CommonFirestore | null, item2: CommonFirestore | null): boolean {
    return item1?.id === item2?.id;
  }

  public close(reason?: number): void {
    this.activeModal.dismiss(reason);
  }

  public handleButtonClick(): void {
    switch (this.currentStep) {
      case Steps.checkEmail:
        this.checkUser();
        break;
      case Steps.userExists:
        this.activeModal.close();
        break;
      case Steps.transferConfirm:
        this.transferUser();
        break;
      case Steps.transferUserAlreadyInTeam:
        this.activeModal.close();
        break;
      case Steps.transferInvalid:
        this.activeModal.close();
        break;
      default:
        this.save();
    }
  }

  private checkTransferUserValid(): void {
    const existingMangerId = this.existingUser?.managerId || '';
    const authUserId = this.auth.profile?.userId;
    const isYou = this.auth.id === this.existingUser?.id;
    const userInTeam = existingMangerId === authUserId;
    const tenantInvalid = this.auth.profile?.tenantId !== this.existingUser?.tenantId;
    if (tenantInvalid) {
      this.options.label = 'userTransferInvalid';
      this.currentStep = Steps.transferInvalid;
      return;
    }
    if (userInTeam || isYou) {
      this.options.label = 'userTransferAlreadyInTeam';
      this.currentStep = Steps.transferUserAlreadyInTeam;
      return;
    }
    this.currentStep = Steps.transferConfirm;
  }

  private transferUser(): void {
    const transferUser = this.existingUser as User;
    const newManagerUserId = this.auth.profile?.userId;
    const newMangerName = this.auth.profile?.fullName;

    transferUser.transferManagerId = newManagerUserId || '';
    transferUser.transferManagerFullName = newMangerName || '';
    transferUser.transferDate = new Date();

    this.currentStep = Steps.transferring;

    const request: Observable<any> = this.usersService
      .update(transferUser.id!, transferUser, true)
      .pipe(mapTo(transferUser.id));

    this.unsub.subs = request.subscribe({
      next: id => {
        this.snackBar.open({
          icon: 'invited',
          title: translate('userTransferOrNewInviteSent', { title: this.existingUser?.fullName || '' })
        });
        this.activeModal.close({ firestoreId: id, ...this.existingUser });
        this.cdr.markForCheck();
      },
      error: err => {
        console.error(err);
        this.snackBar.open({
          icon: 'invited',
          title: translate('userTransferOrNewInviteSentFailed')
        });
        this.cdr.markForCheck();
      }
    });
  }

  private checkUser(): void {
    const emailControl = this.form.get('email');
    emailControl?.markAsTouched();

    if (!emailControl?.valid) {
      return;
    }
    const modalWidth = 13 * this.email.length;

    this.currentStep = Steps.checking;
    this.unsub.subs = this.usersGlobalService.getList([['email', '==', this.email]]).subscribe(users => {
      this.existingUser = users[0] || null;
      this.currentStep = this.existingUser
        ? this.options.managerInvite
          ? Steps.transferCheck
          : Steps.userExists
        : Steps.inviteUser;

      if (!this.existingUser) {
        this.save();
        return;
      }

      if (this.currentStep === Steps.userExists) {
        this.options.label = 'userAlreadyExists';
      }

      if (this.currentStep === Steps.transferCheck) {
        this.options.label = 'userTransferInvitation';
        this.checkTransferUserValid();
      }

      (this.elementRef.nativeElement.parentElement as HTMLElement).style.width = `${Math.max(modalWidth, 450)}px`;
      (this.elementRef.nativeElement.parentElement as HTMLElement).style.margin = 'auto';
      this.cdr.markForCheck();
    });
  }

  private getCurrenciesList(): void {
    this.unsub.subs = this.currencyService.getList().subscribe(res => {
      this.currencies = res.sort(
        (a, b) => a.currencyCode.localeCompare(b.currencyCode) && a.description.localeCompare(b.description)
      );
      this.cdr.markForCheck();
    });
  }

  private getDivisionsList(): void {
    this.unsub.subs = this.divisionService.getList().subscribe(res => {
      this.divisions = res;
      this.cdr.markForCheck();
    });
  }

  private getAreasList(): void {
    this.unsub.subs = this.areaService.getList().subscribe(res => {
      this.areas = res;
      this.cdr.markForCheck();
    });
  }

  private getEntitiesList(): void {
    this.unsub.subs = this.entityService.getList().subscribe(res => {
      this.entities = res;
      this.cdr.markForCheck();
    });
  }

  private getRolesList(): void {
    this.unsub.subs = this.rolesService.getList().subscribe(res => {
      this.roles = res;
      if (!this.options.docId) {
        const employee = this.roles.find(role => role.name.toLowerCase() === 'employee');
        if (employee) {
          this.form.patchValue({
            role: employee
          });
        }
      }
      this.cdr.markForCheck();
    });
  }

  private createForm(): void {
    this.form = this.fb.group({
      accountLanguage: [null, [Validators.required]],
      area: null,
      country: [null, Validators.required],
      currency: [null, Validators.required],
      delegate: null,
      division: [
        null,
        (control: FormControl) => {
          return this.hideCorporateInfo ? null : Validators.required(control);
        }
      ],
      email: [
        {
          value: '',
          disabled: this.options.docId
        },
        [Validators.required, Validators.pattern(emailRegExp)]
      ],
      entity: [null, [Validators.required]],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      manager: null,
      phone: ['', [Validators.pattern(phoneRegExp)]],
      role: [
        null,
        (control: FormControl) => {
          if (this.hideCorporateInfo) {
            return null;
          }
          return this.options.securityControl ? Validators.required(control) : null;
        }
      ]
    });
  }

  private save(): void {
    triggerValidation(this.form);

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    const updatedModel = UserModelService.prepareUserModel(
      this.form.getRawValue(),
      this.userModel || {},
      this.initialStatus
    );

    const requests: Observable<any>[] = [
      this.options.docId
        ? this.usersService.update(this.options.docId, updatedModel, true).pipe(mapTo(this.options.docId))
        : this.usersService.create(updatedModel as User).pipe(map(res => res.id))
    ];

    if (this.userModel?.delegateId !== updatedModel.delegateId) {
      if (this.userModel?.delegateId) {
        requests.push(
          this.usersService.update(
            this.userModel.delegateId,
            { delegators: firebase.firestore.FieldValue.arrayRemove(this.options.docId) as any },
            true
          )
        );
      }

      if (updatedModel.delegateId) {
        requests.push(
          this.usersService.update(
            updatedModel.delegateId,
            { delegators: firebase.firestore.FieldValue.arrayUnion(this.options.docId) as any },
            true
          )
        );
      }
    }

    this.currentStep = this.currentStep === Steps.inviteUser ? Steps.inviting : Steps.saving;

    this.unsub.subs = forkJoin(requests)
      .pipe(
        switchMap(([id]) => {
          if (updatedModel.userId === this.auth.profile?.userId) {
            return forkJoin([of(id), this.auth.setProfile({ ...this.auth.profile, ...updatedModel } as User)]);
          }
          return of([id]);
        })
      )
      .subscribe({
        next: ([id]) => {
          if (this.options.managerInvite) {
            this.snackBar.open({
              icon: 'invited',
              title: translate('userTransferOrNewInviteSent', { title: updatedModel.fullName || '' })
            });
          }
          this.activeModal.close({ firestoreId: id, ...updatedModel });
        },
        error: err => {
          console.error(err);
          this.currentStep = Steps.saveUser;
          this.cdr.markForCheck();
        }
      });
  }
}
