import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

import { SnackBarComponent } from './snack-bar.component';
import { SnackBarOptions } from './snack-bar-options.interface';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  constructor(private snackBar: MatSnackBar) {
  }

  open(data: SnackBarOptions, config: MatSnackBarConfig = {}) {
    this.snackBar.openFromComponent(SnackBarComponent, {
      duration: 3000,
      data,
      ...config
    });
  }
}
