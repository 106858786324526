import { Injectable } from '@angular/core';
import { UsersService } from './users.service';
import { CollectionReference, Query } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class UsersGlobalService extends UsersService {
  protected queryFunction(ref: CollectionReference): Query {
    return ref;
  }
}
