import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, Self, SimpleChanges } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Observable, of } from 'rxjs';

import { AutoUnsubscribeService } from '../../core/services/auto-unsubscribe.service';
import { UserAvatarService } from './user-avatar.service';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
  providers: [AutoUnsubscribeService],
})
export class UserAvatarComponent implements OnChanges, OnInit {
  @Input() initials = '';
  @Input() userId = '';
  @Input() firstName = '';
  @Input() lastName = '';
  @Input() status = '';
  @Input() statusIcon = false;

  hasImage = false;
  imageUrl$: Observable<string> | undefined;
  imageUrl = '';

  constructor(private afStorage: AngularFireStorage,
              private avatarService: UserAvatarService,
              private cdr: ChangeDetectorRef,
              @Self() private unsub: AutoUnsubscribeService) {
  }

  ngOnInit() {
    this.unsub.subs = this.avatarService.updateAvatarImage$
      .subscribe(({ url, userId }) => {
        if (this.userId === userId) {
          this.imageUrl$ = of(url);
          this.imageUrl = url;
          this.cdr.markForCheck();
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.firstName && this.lastName && !this.initials) {
      const firstChar = (`${this.firstName}` || '').substring(0, 1).toUpperCase();
      const secondChar = (`${this.lastName}` || '').substring(0, 1).toUpperCase();
      this.initials = `${firstChar}${secondChar}`;
    }
    if (this.userId && changes?.userId?.currentValue !== changes.userId?.previousValue) {
      this.imageUrl$ = this.getRef()
        .getDownloadURL()
        .pipe(
          tap((imageUrl: string) => this.imageUrl = imageUrl)
        );
    }
  }

  load() {
    this.hasImage = true;
  }

  private getRef() {
    return this.afStorage.ref(`/user_photos/${this.userId}.jpg`);
  }

}
