import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { UserRoles } from '../interfaces/user-roles.enum';
import { AuthService } from './oauth0/oauth0.service';

@Injectable({
  providedIn: 'root'
})
export class AdministratorGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private router: Router
  ) {
  }

  canActivate() {
    return this.hasAdministratorRole() ? true : this.router.parseUrl('/');
  }

  private hasAdministratorRole() {
    return this.auth.profile?.role === UserRoles.Administrator;
  }
}
