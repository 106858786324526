<app-modal-header [label]="label" [params]="params" [hideClose]="hideClose" (closeDialog)="close()"></app-modal-header>

<div class="modal-body text-center" *transloco="let translate">
  <div *ngIf="icon" class="confirm-icon">
    <svg>
      <use [attr.xlink:href]="'#' + icon"></use>
    </svg>
  </div>

  <p *ngIf="message" class="message" [innerHTML]="translate(message, params)"></p>
  <p *ngIf="item" class="item" [innerHTML]="item"></p>
  <mat-form-field appearance="standard" class="w-100" *ngIf="showInput">
    <mat-label>{{ translate(inputPlaceholder) }}</mat-label>
    <input
      type="text"
      matInput
      data-testid="new_gift_item_description"
      [formControl]="inputControl"
      [placeholder]="inputPlaceholder ? translate(inputPlaceholder) : ''"
      [attr.aria-label]="translate(inputPlaceholder)"
      autocomplete="off" />
  </mat-form-field>
</div>

<div class="modal-footer justify-content-between" *transloco="let translate">
  <button
    type="button"
    *ngFor="let button of buttons; let i = index"
    [attr.data-testid]="'confirm_modal_button_' + i"
    [class]="button.class"
    [attr.ngbAutofocus]="button.focused"
    (click)="buttonClick(button.value)">
    <svg *ngIf="button.icon">
      <use [attr.xlink:href]="'#' + button.icon"></use>
    </svg>
    <span *ngIf="button.label">{{ translate(button.label) }}</span>
  </button>
</div>
