<div *ngIf="preLoader" class="pre-load-modal">
  <div class="loader_main">
    <div class="loader"></div>
  </div>
</div>

<app-modal-header [label]="options.label" (closeDialog)="close($event)"></app-modal-header>

<div class="modal-body" *transloco="let translate">
  <form *ngIf="!existingUser" [formGroup]="form">
    <mat-form-field *ngIf="!editMode" appearance="standard" class="w-100">
      <mat-label>{{ translate("email") }}</mat-label>
      <input
        data-testid="employee_email"
        type="email"
        name="email"
        matInput
        formControlName="email"
        [attr.aria-label]="translate('email')" />
    </mat-form-field>

    <ng-container *ngIf="editMode">
      <p class="email-badge">
        <span>{{ email }}</span>
      </p>

      <h2>{{ translate("personalInfo") }}</h2>

      <div class="controls-container">
        <mat-form-field appearance="standard">
          <mat-label>{{ translate("firstName") }}</mat-label>
          <input
            data-testid="employee_first_name"
            type="text"
            name="firstname"
            matInput
            formControlName="firstName"
            [attr.aria-label]="translate('firstName')" />
        </mat-form-field>

        <mat-form-field appearance="standard">
          <mat-label>{{ translate("lastName") }}</mat-label>
          <input
            data-testid="employee_last_name"
            type="text"
            name="lastname"
            matInput
            formControlName="lastName"
            [attr.aria-label]="translate('lastName')" />
        </mat-form-field>

        <mat-form-field appearance="standard">
          <mat-label>{{ translate("phone") }} ({{ translate("optional") }})</mat-label>
          <input
            type="tel"
            data-testid="employee_phone"
            name="phone"
            matInput
            formControlName="phone"
            [attr.aria-label]="translate('phone')" />
        </mat-form-field>
      </div>

      <ng-container *ngIf="!hideCorporateInfo">
        <h2>{{ translate("corporateInfo") }}</h2>
        <div class="controls-container">
          <mat-form-field appearance="standard">
            <mat-label>{{ translate("division") }}</mat-label>
            <app-autocomplete
              data-testid="employee_division"
              formControlName="division"
              labelField="name"
              [options]="divisions">
            </app-autocomplete>
          </mat-form-field>

          <mat-form-field appearance="standard">
            <mat-label>{{ translate("entity") }}</mat-label>
            <app-autocomplete
              data-testid="employee_entity"
              formControlName="entity"
              labelField="name"
              [options]="entities">
            </app-autocomplete>
          </mat-form-field>

          <mat-form-field appearance="standard">
            <mat-label>{{ translate("area") }} ({{ translate("optional") }})</mat-label>
            <app-autocomplete data-testid="employee_area" formControlName="area" labelField="name" [options]="areas">
            </app-autocomplete>
          </mat-form-field>

          <mat-form-field appearance="standard" *ngIf="options?.managerControl">
            <mat-label>{{ translate("manager") }} ({{ translate("optional") }})</mat-label>
            <app-algolia-autocomplete
              data-testid="employee_manager"
              formControlName="manager"
              [algoliaIndex]="usersIndex"
              [searchOptions]="usersSearchOptions"
              labelField="fullName"
              [defaultOption]="translate('noneOption')"
              [optionTemplate]="delegateOption">
              <ng-template let-option #delegateOption>
                <p class="delegate-name">{{ option.fullName }}</p>
                <p class="delegate-email">{{ option.email }}</p>
              </ng-template>
            </app-algolia-autocomplete>
          </mat-form-field>

          <mat-form-field appearance="standard" *ngIf="options?.managerControl">
            <mat-label>{{ translate("securityRole") }}</mat-label>
            <app-autocomplete data-testid="employee_role" formControlName="role" labelField="name" [options]="roles">
            </app-autocomplete>
          </mat-form-field>
        </div>
      </ng-container>

      <h2>{{ translate("additionalInfo") }}</h2>
      <div class="controls-container">
        <mat-form-field appearance="standard">
          <mat-label>{{ translate("country") }}</mat-label>
          <app-autocomplete
            data-testid="employee_country"
            formControlName="country"
            labelField="name"
            [options]="countries">
          </app-autocomplete>
        </mat-form-field>

        <mat-form-field appearance="standard">
          <mat-label>{{ translate("currency") }}</mat-label>
          <mat-select
            data-testid="employee_currency"
            formControlName="currency"
            disableOptionCentering="true"
            disableRipple="true"
            [compareWith]="compareFunction">
            <mat-option *ngFor="let currency of currencies" [value]="currency">
              {{ currency.currencyCode }} - {{ currency.description }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="standard">
          <mat-label>{{ translate("language") }}</mat-label>
          <app-autocomplete
            data-testid="employee_accountLanguage"
            formControlName="accountLanguage"
            labelField="name"
            [options]="languages">
          </app-autocomplete>
        </mat-form-field>

        <mat-form-field appearance="standard" *ngIf="isActiveUser">
          <mat-label>{{ translate("delegate") }} ({{ translate("optional") }})</mat-label>
          <app-algolia-autocomplete
            data-testid="employee_delegate"
            formControlName="delegate"
            [algoliaIndex]="usersIndex"
            [searchOptions]="usersSearchOptions"
            [additionalOptions]="[noneUser]"
            labelField="fullName"
            [optionTemplate]="delegateOption">
            <ng-template let-option #delegateOption>
              <p class="delegate-name">{{ option.fullName }}</p>
              <p class="delegate-email">{{ option.email }}</p>
            </ng-template>
          </app-algolia-autocomplete>
        </mat-form-field>
      </div>
    </ng-container>
  </form>

  <div *ngIf="existingUser && currentStep === allSteps.userExists" class="text-center mt-4">
    <app-user-avatar
      class="initials"
      [status]="'Invited'"
      [firstName]="existingUser.firstName || ''"
      [lastName]="existingUser.lastName || ''"
      [userId]="existingUser.userId || ''"></app-user-avatar>
    <p class="user-exist-name">{{ existingUser.fullName }}</p>
    <p class="user-exist-text">{{ translate("emailAlreadyExists") }}</p>
    <p class="user-exist-email">{{ existingUser.email }}</p>
  </div>

  <!--transferMode-->
  <ng-container *ngIf="existingUser && transferMode">
    <div class="text-center mt-4">
      <app-user-avatar
        class="initials"
        [status]="'Invited'"
        [firstName]="existingUser!.firstName || ''"
        [lastName]="existingUser!.lastName || ''"
        [userId]="existingUser!.userId || ''">
      </app-user-avatar>
      <p class="user-exist-name">{{ existingUser!.fullName }}</p>
      <p class="user-exist-text">{{ translate(transferModeInfoLabel) }}</p>
      <p class="user-exist-email">{{ existingUser!.email }}</p>
    </div>
  </ng-container>
</div>

<div class="modal-footer" [class.justify-content-center]="existingUser" *transloco="let translate">
  <button
    data-testid="employee_details_action_btn"
    type="button"
    class="btn btn-lg load-btn {{ buttonClass }}"
    [class.btn-active]="!existingUser || transferMode"
    [class.btn-info]="existingUser && currentStep === allSteps.userExists"
    [class.loading]="processing"
    [disabled]="processing"
    (click)="handleButtonClick()">
    <span>{{ translate(buttonLabel) }}</span>
    <span *ngIf="processing" class="spinner-border ml-3" role="status" aria-hidden="true"></span>
  </button>
</div>
