import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Auth0Client, AuthenticationError, IdToken, RedirectLoginResult } from '@auth0/auth0-spa-js';
import { Tenant, User } from '@incendi-io/types';
import { TranslocoService } from '@ngneat/transloco';
import { addHours } from 'date-fns';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, forkJoin, from, of } from 'rxjs';
import { catchError, map, mapTo, switchMap, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { appConstants } from '../../app.constants';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly client = new Auth0Client({
    client_id: environment.authConfig.clientID,
    domain: environment.authConfig.domain,
    redirect_uri: `${window.location.origin}`
  });

  private get getTokenSilently$(): Observable<string> {
    return from(this.client.getTokenSilently());
  }

  private get getIdTokenClaims$(): Observable<IdToken | undefined> {
    return from(this.client.getIdTokenClaims());
  }

  private get handleRedirectCallback$(): Observable<RedirectLoginResult> {
    return from(this.client.handleRedirectCallback());
  }

  private get isAuthenticated$(): Observable<boolean> {
    return from(this.client.isAuthenticated());
  }

  public get fullName(): string {
    return this.profile ? `${this.profile.firstName} ${this.profile.lastName}` : '';
  }

  public get id(): string | undefined {
    return this.profile ? this.profile.id : undefined;
  }

  public get userId(): string | undefined {
    return this.profile ? this.profile.userId || undefined : undefined;
  }

  public idToken: string | null = null;
  public algoliaToken: string | null = null;
  public profile: User | null = null;
  public tenant: Tenant | null = null;
  private readonly profile$ = new BehaviorSubject<User | null>(null);

  private firebaseToken: string | null = null;
  private readonly jwtHelper = new JwtHelperService();

  constructor(
    private readonly api: ApiService,
    private readonly cookie: CookieService,
    private readonly ngFireAuth: AngularFireAuth,
    private readonly translate: TranslocoService
  ) {}

  public checkCookies(): Observable<string> {
    /** Check if idToken is present */
    const idToken = this.cookie.get(appConstants.cookies.appToken);
    if (!idToken) {
      return of('');
    }

    /** Check if idToken has expired */
    if (this.jwtHelper.isTokenExpired(idToken)) {
      this.clearCookies();
      return of('');
    }

    this.idToken = idToken;

    return of(this.cookie.get(appConstants.cookies.firebaseToken)).pipe(
      /** Check if firebase token is present or has expired */
      switchMap(fbToken => {
        if (!fbToken || this.jwtHelper.isTokenExpired(fbToken)) {
          return this.getFirebaseToken();
        }
        this.firebaseToken = fbToken;
        return of(void 0);
      }),
      /** Check if algolia token is present */
      map(() => this.cookie.get(appConstants.cookies.searchToken)),
      switchMap(algoliaToken => {
        if (!algoliaToken) {
          return this.getAlgoliaToken();
        }
        this.algoliaToken = algoliaToken;
        return of(void 0);
      }),
      /** Log in to firebase */
      switchMap(() => this.loginToFirebase())
    );
  }

  public checkSession(): Observable<string> {
    let redirectUrl = '/';

    return this.isAuthenticated$.pipe(
      /** Check if id token is in cache or get it from auth0 */
      switchMap(isLoggedIn =>
        isLoggedIn
          ? of(void 0)
          : this.getTokenSilently$.pipe(
              catchError((e: Error) => {
                /**
                 * getTokenSilently throws the "Login required" error when the user must be redirected to the login page
                 * So we call the login method for this redirect and throw an error to stop the auth flow
                 */
                if (e.message.includes('Login required')) {
                  this.login().subscribe();
                }
                throw e;
              })
            )
      ),
      /** Handle code and state from url */
      switchMap(() => {
        const url = new URL(window.location.href);
        if (url.searchParams.has('state')) {
          return this.handleRedirectCallback$.pipe(
            map(({ appState }) => {
              redirectUrl = appState;
              /** Remove auth query params from url */
              url.searchParams.delete('state');
              url.searchParams.delete('code');
              window.history.replaceState(null, '', url.href);
            })
          );
        } else {
          return of(void 0);
        }
      }),
      /** Get id token for local usage */
      switchMap(() => this.getAuthToken()),
      /** Get firebase and algolia tokens from API */
      switchMap(() => forkJoin([this.getAlgoliaToken(), this.getFirebaseToken()])),
      /** If there is an app state, redirect to it, or continue the auth flow and login to firebase */
      switchMap(() => {
        if (redirectUrl !== '/') {
          window.location.assign(redirectUrl);
          throw 0; // Stop initialization process
        }
        return this.loginToFirebase();
      })
    ) as Observable<string>; // responding with firebase userId
  }

  public login(): Observable<void> {
    return this.getAppState().pipe(
      switchMap(appState =>
        this.client.loginWithRedirect({
          appState,
          redirect_uri: window.location.origin
        })
      )
    );
  }

  public logout(): Observable<void> {
    this.clearCookies();
    return from(
      this.client.logout({
        client_id: environment.authConfig.clientID,
        returnTo: window.location.origin
      }) || [void 0]
    );
  }

  public getLiveProfile$(): Observable<User | null> {
    return this.profile$.asObservable();
  }

  public setProfile(profile: User): Observable<void> {
    this.profile = profile;
    this.profile$.next(this.profile);
    const language = this.profile.accountLanguageId || this.translate.getDefaultLang();
    return this.translate.load(language).pipe(
      tap(() => {
        this.translate.setActiveLang(language);
      }),
      mapTo(void 0)
    );
  }

  private getAuthToken(): Observable<void> {
    return this.getIdTokenClaims$.pipe(
      map(claims => {
        if (!claims?.__raw) {
          throw new Error(`Can't get id token`);
        }
        this.idToken = claims.__raw;
        this.setCookie(appConstants.cookies.appToken, claims.__raw);
      })
    );
  }

  private getAlgoliaToken(): Observable<void> {
    return this.api.core.getAlgoliaToken().pipe(
      map(token => {
        this.algoliaToken = token;
        this.setCookie(appConstants.cookies.searchToken, token);
      })
    );
  }

  private getFirebaseToken(): Observable<void> {
    return this.api.core.getFireBaseToken().pipe(
      map(token => {
        this.firebaseToken = token;
        this.setCookie(appConstants.cookies.firebaseToken, token);
      })
    );
  }

  private loginToFirebase(): Observable<string> {
    return from(this.ngFireAuth.signInWithCustomToken(this.firebaseToken!)).pipe(
      map(credentials => {
        if (!credentials.user) {
          throw new Error('No user in firebase credentials');
        }
        return credentials.user.uid;
      })
    );
  }

  private getAppState(): Observable<string> {
    const url = new URL(window.location.href);
    if (url.searchParams.has('state') && url.searchParams.has('error')) {
      /** Decode app state on error
       * Because auth0 throws an error while handling auth errors we need to "catch" the state
       * We need to pass original app state that was before the error
       */
      return this.handleRedirectCallback$.pipe(catchError((e: AuthenticationError) => of(e.appState)));
    }

    /** Pass current url as the app state */
    return of(window.location.href.replace(window.location.origin, ''));
  }

  private setCookie(name: string, value: string): void {
    const expires = addHours(new Date(), appConstants.cookies.expiresHours);
    this.cookie.set(name, value, expires, '/', environment.cookiesDomain);
  }

  private clearCookies(): void {
    this.cookie.deleteAll('/', environment.cookiesDomain);
  }
}
