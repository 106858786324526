import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { from, Observable } from 'rxjs';

import { EmployeeDetailsComponent } from './employee-details.component';
import { EmployeeDetailsOptions } from './employee-details.interfce';

@Injectable()
export class EmployeeDetailsService {
  private readonly defaultOptions: Partial<EmployeeDetailsOptions> = {
    managerControl: true,
    securityControl: true
  };

  constructor(private modal: NgbModal) {
  }

  public openEmployee(options: EmployeeDetailsOptions): Observable<any> {
    const modal = this.modal.open(EmployeeDetailsComponent,
      {
        centered: true,
        windowClass: 'your-trust-details-modal'
      }
    );
    (modal.componentInstance as EmployeeDetailsComponent).options = {
      ...this.defaultOptions,
      ...options
    };
    return from(modal.result.catch(() => false));
  }
}
