import { Observable, forkJoin, from, of } from 'rxjs';
import { User, UserStatuses } from '@incendi-io/types';
import { map, switchMap, tap } from 'rxjs/operators';

import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthService } from '../oauth0/oauth0.service';
import { BaseFirebaseService } from './base-firebase.service';
import { Injectable } from '@angular/core';
import { default as _chunk } from 'lodash-es/chunk';
import { appConstants } from '../../app.constants';
import firebase from 'firebase/compat/app';

@Injectable({
  providedIn: 'root'
})
export class UsersService extends BaseFirebaseService<User> {
  constructor(auth: AuthService, db: AngularFirestore) {
    super(auth, db, appConstants.firebase.collections.users);
  }
  // Search user by Auth0 userId
  public getUserByUserId(userId: string): Observable<User | null> {
    return this.db
      .collection<User>(this.collectionName, ref => {
        return ref.where('userId', '==', userId);
      })
      .get()
      .pipe(
        map(querySnapshot => {
          return querySnapshot.empty ? [] : querySnapshot.docs.map(doc => this.mapFunction(doc));
        }),
        map(users => users[0] || null)
      );
  }

  public getLiveUserByUserId$(userId: string): Observable<User | null> {
    return this.db
      .collection<User>(this.collectionName, ref => {
        return ref.where('userId', '==', userId);
      })
      .snapshotChanges()
      .pipe(
        map(documentChangeAction => {
          return documentChangeAction.map(docChange => this.mapFunction(docChange.payload.doc));
        }),
        map(users => users[0] || null)
      );
  }

  public getUsersByManager(userId: string): Observable<User[]> {
    return this.db
      .collection<User>(this.collectionName, ref => {
        return ref.where('managerId', '==', userId);
      })
      .get()
      .pipe(map(querySnapshot => (querySnapshot.empty ? [] : querySnapshot.docs.map(doc => this.mapFunction(doc)))));
  }

  public getListByIds(ids?: string[]): Observable<User[]> {
    if (!ids?.length) {
      return of([]);
    }
    return forkJoin(
      _chunk(ids, 10).map(chunk => {
        return this.db
          .collection<User>(this.collectionName, ref => {
            return this.queryFunction(ref).where(firebase.firestore.FieldPath.documentId(), 'in', chunk);
          })
          .get();
      })
    ).pipe(
      map(querySnapshots => {
        const users: User[] = [];
        querySnapshots.forEach(querySnapshot => {
          users.push(...querySnapshot.docs.map(doc => this.mapFunction(doc)));
        });
        return users;
      })
    );
  }

  public deactivate(docId: string): Observable<void> {
    return this.update(docId, { status: UserStatuses.deactivated }, true);
  }

  public activate(docId: string): Observable<void> {
    return this.update(docId, { status: UserStatuses.active }, true);
  }

  public batchSave(users: Partial<User>[], merge = false): Observable<any> {
    const chunks = _chunk<Partial<User>>(users, appConstants.firebase.maxBatchSize);
    const collectionRef = this.db.firestore.collection(this.collectionName);
    const requests: Observable<any>[] = [];
    chunks.forEach(chunk => {
      const batch = this.db.firestore.batch();
      chunk.forEach(user => {
        const docRef = user.id ? collectionRef.doc(user.id) : collectionRef.doc();
        delete user.id;
        batch.set(docRef, user, { merge });
      });
      requests.push(from(batch.commit()));
    });

    if (!requests.length) {
      return of([]);
    }

    return forkJoin(requests);
  }

  public joinTeam(user: User): Observable<void> {
    if (!user.id || !user.transferManagerId) {
      return of(void 0);
    }
    return this.update(
      user.id!,
      {
        managerId: user.transferManagerId,
        managerFullName: user.transferManagerFullName || '',
        transferManagerId: null as any,
        transferManagerFullName: null as any,
        transferDate: null as any
      },
      true
    );
  }

  public declineTeam(user: User): Observable<void> {
    if (!user.id) {
      return of(void 0);
    }
    return this.update(
      user.id!,
      {
        transferManagerId: null as any,
        transferManagerFullName: null as any,
        transferDate: null as any
      },
      true
    );
  }

  public getDelegatorUserId(user?: User): Observable<string[]> {
    let delegatorUserIds: string[] = [];
    if (!user) {
      return of([]);
    }
    if (user.delegators && user.delegators.length) {
      return this.get(user.id!).pipe(
        //1st
        switchMap(user => {
          const delegatorIds: string[] = user.delegators || [];
          //2nd
          return this.getListByIds(delegatorIds).pipe(
            map(delegators => {
              return delegators.map(delegator => delegator.userId || '');
            }),
            tap(userIds => {
              delegatorUserIds = userIds;
              return of(delegatorUserIds);
            })
          );
        })
      );
    }
    return of([]);
  }
}
