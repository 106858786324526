import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';

import { NewsCardInfo, NewsCategory } from '../../core/services/contentful.service';
import { ROUTER_LINKS } from '../../interfaces/router-links.enum';

@Component({
  selector: 'app-news-card',
  templateUrl: './news-card.component.html',
  styleUrls: ['./news-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewsCardComponent {
  @Input() public news?: NewsCardInfo;
  @Input() public category?: NewsCategory;
  @Input() public recent = false;
  @Input() public routeRoot = ROUTER_LINKS.HOME_CONTENT;

  public isError = false;

  public get link(): string {
    return `/${this.routeRoot}/news/${this.news?.id || ''}`;
  }

  public get qParams(): { [k: string]: any; } {
    return {
      title: this.news?.title,
      categoryId: this.category?.id,
      categoryName: this.category?.title
    };
  }

  constructor(private cdr: ChangeDetectorRef) {
  }

  public imgError() {
    this.isError = true;
    this.cdr.markForCheck();
  }

  public load() {
    this.isError = false;
    this.cdr.markForCheck();
  }
}
