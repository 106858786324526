<nav class="navbar" *transloco="let translate">
  <div class="logo-wrapper">
    <div class="nav-bar-logo" ngbDropdown>
      <div class="union-wrapper active" *ngIf="false">
        <svg>
          <use xlink:href="#union"></use>
        </svg>
      </div>
      <a class="logo-image-wrapper" data-testid="link_logo_header" [routerLink]="homeLink">
        <svg>
          <use xlink:href="#logo-your-trust"></use>
        </svg>
        <h1>yourTrust</h1>
      </a>
      <div ngbDropdownMenu class="logo-dropdown-menu button-drop-down-menu">
        <h3>{{ translate("switchTo") }}</h3>
        <button ngbDropdownItem data-testid="in_mail_dropdown_menu">
          <span class="app-icon-wrapper empty"></span>
          <span>InMail</span>
        </button>
        <button ngbDropdownItem data-testid="in_facebook_dropdown_menu">
          <span class="app-icon-wrapper empty"></span>
          <span>InFacebook</span>
        </button>
        <button ngbDropdownItem class="active" data-testid="yourtrust_dropdown_menu">
          <span class="app-icon-wrapper">
            <svg>
              <use xlink:href="#logo-your-trust"></use>
            </svg>
          </span>
          <span>yourTrust</span>
          <svg>
            <use xlink:href="#check"></use>
          </svg>
        </button>
        <button ngbDropdownItem data-testid="in_linkedin_dropdown_menu">
          <span class="app-icon-wrapper empty"></span>
          <span>InLinkedIn</span>
        </button>
        <button ngbDropdownItem data-testid="in_apple_dropdown_menu">
          <span class="app-icon-wrapper empty"></span>
          <span>InApple</span>
        </button>
      </div>
    </div>
  </div>

  <div class="main_menu">
    <a
      routerLinkActive="main_menu--active"
      data-testid="link_home_header"
      [routerLink]="homeLink"
      class="main_menu_link"
      >{{ translate("home") }}</a
    >
    <a
      routerLinkActive="main_menu--active"
      data-testid="link_team_activity_header"
      [routerLink]="teamLink"
      class="main_menu_link"
      >{{ translate("teamActivity") }}</a
    >
    <a
      routerLinkActive="main_menu--active"
      data-testid="link_review_header"
      [routerLink]="reviewLink"
      class="main_menu_link">
      <span *ngIf="(this.giftsForApproval$ | async)?.length" class="dot"></span>
      {{ translate("review") }}
    </a>
    <a
      *ngIf="isAdmin"
      routerLinkActive="main_menu--active"
      data-testid="link_analytics_header"
      [routerLink]="analyticsLink"
      class="main_menu_link"
      >{{ translate("analytics") }}</a
    >
    <a
      *ngIf="isAdmin && isEnterprise"
      routerLinkActive="main_menu--active"
      data-testid="link_preview_content_header"
      [routerLink]="previewContentLink"
      class="main_menu_link"
      >{{ translate("previewContent") }}</a
    >
    <a
      *ngIf="isAdmin"
      data-testid="link_settings_header"
      routerLinkActive="main_menu--active"
      [routerLink]="staffLink"
      class="main_menu_link"
      >{{ translate("settings") }}</a
    >
  </div>

  <div class="profile-wrapper">
    <app-profile-header></app-profile-header>
  </div>
</nav>
