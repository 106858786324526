import { NgModule } from '@angular/core';

import { FilesizePipe } from './filesize.pipe';
import { CapitalizePipe } from './capitalize.pipe';
import { FormatCurrencyPipe } from './format-currency.pipe';

@NgModule({
  declarations: [
    FilesizePipe,
    CapitalizePipe,
    FormatCurrencyPipe
  ],
  exports: [
    FilesizePipe,
    CapitalizePipe,
    FormatCurrencyPipe
  ]
})
export class IncendiPipesModule {
}
