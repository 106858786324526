import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { HashMap } from '@ngneat/transloco';

@Component({
  selector: 'app-modal-header',
  templateUrl: './modal-header.component.html',
  styleUrls: ['./modal-header.component.scss']
})
export class ModalHeaderComponent {
  @HostBinding('class.modal-header') private hostClass = true;

  @Input() label!: string;
  @Input() params?: HashMap;
  @Input() hideClose?: boolean;

  @Output() closeDialog = new EventEmitter<number>();

  public closeClick(): void {
    /**
     * Add a new dismiss reason
     * 2 - close click
     * See ModalDismissReasons
     */
    this.closeDialog.emit(2);
  }
}
