import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

interface UpdateImage {
  url: string;
  userId: string;
}

@Injectable({
  providedIn: 'root'
})
export class UserAvatarService {
  readonly updateAvatarImage$: Subject<UpdateImage> = new Subject<UpdateImage>();

  updateImage(updateImage: UpdateImage) {
    this.updateAvatarImage$.next(updateImage);
  }
}
