export const environment = {
  production: true,
  algoliaAppId: '2QVK4JVZJS',
  api: 'https://api.incendi.io',
  authConfig: {
    clientID: 'qU9sXrOeb0BnO1EWfAIGbdsBjZYJuv0W',
    domain: 'login.incendi.io'
  },
  contentfulApiUrl: 'https://australia-southeast1-incendi-prod.cloudfunctions.net/contentful_api',
  cookiesDomain: 'incendi.io',
  firebase: {
    apiKey: 'AIzaSyCyHMAO2uJpy93GE4fdT7Z2bwzF74Pq6bM',
    appId: '1:86480217150:web:52b038b8e937c9ed9f5d6f',
    authDomain: 'incendi-prod.firebaseapp.com',
    messagingSenderId: '86480217150',
    projectId: 'incendi-prod',
    storageBucket: 'incendi-prod.appspot.com'
  },
  gtmId: 'GTM-K3WRXKC',
  zendeskKey: 'adf89b4e-44d8-40c6-8b58-f0188d73fefe'
};
