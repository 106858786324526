import { Language } from '@incendi-io/types';
import { Locale } from 'date-fns';
import { enAU, ms } from 'date-fns/locale';

export const languages: Language[] = [
  {
    id: 'en',
    name: 'English'
  },
  {
    id: 'ms',
    name: 'Malay'
  }
];

export const dateLocales: { [key: string]: Locale } = {
  en: enAU,
  ms
};
