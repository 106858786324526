import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AuthService } from './core/oauth0/oauth0.service';
import { AlgoliaService } from './core/services/algolia.service';
import { GtmService } from './core/services/gtm.service';
import { TenantsService } from './core/services/tenant.service';
import { UsersService } from './core/services/users.service';
import { ZendeskService } from './core/services/zendesk.service';

export function appInitializer(
  authService: AuthService,
  usersService: UsersService,
  tenantService: TenantsService,
  algolia: AlgoliaService,
  gtmService: GtmService
) {
  /** Check tokens from cookies and login to firebase */
  return () =>
    authService.checkCookies().pipe(
      /** If no valid cookies, process auth0 authentication */
      switchMap(userId => (userId ? of(userId) : authService.checkSession())),
      /** Get current user from firebase */
      switchMap(userId => usersService.getUserByUserId(userId)),
      /** Store profile and initialize Zendesk */
      map(user => {
        if (!user) {
          throw new Error('User not found');
        }
        authService.setProfile(user).subscribe();
        ZendeskService.initialize(user.fullName, user.email);
      }),
      /** Get current tenant from firebase */
      switchMap(() => tenantService.getCurrent()),
      /** Store tenant, initialize gtm and algolia client */
      map(tenant => {
        if (!tenant) {
          throw new Error('Current tenant not found');
        }
        authService.tenant = tenant;
        gtmService.initialize(authService.profile!, tenant);
        algolia.initClient(tenant, authService.algoliaToken!);
      })
    );
}
