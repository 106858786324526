<input type="text"
       [name]="name"
       matInput
       [formControl]="control"
       [matAutocomplete]="auto"
       #inputElement>

<mat-autocomplete #auto="matAutocomplete"
                  [displayWith]="displayFn"
                  (opened)="panelOpened()"
                  (closed)="panelClosed()"
                  (optionSelected)="selectOption($event)">
  <div class="scroll-container" cdkScrollable #scrollContainer>
    <mat-option *ngIf="defaultOption && hasFirstResults" [value]="null">{{ defaultOption }}</mat-option>

    <mat-option *ngFor="let option of options" [value]="option">
      <ng-container *ngIf="optionTemplate; else defaultOption">
        <ng-container [ngTemplateOutlet]="optionTemplate"
                      [ngTemplateOutletContext]="{ $implicit: option }">
        </ng-container>
      </ng-container>

      <ng-template #defaultOption>
        {{ getOptionLabel(option) }}
      </ng-template>
    </mat-option>
  </div>
</mat-autocomplete>
