<div class="profile button-drop-down" ngbDropdown *transloco="let translate">
  <ng-container *ngIf="user$ | async as user">
    <div class="profile_data" ngbDropdownToggle>
      <p class="profile_name mb-0">{{ user.fullName }}</p>
    </div>
    <app-user-avatar
      ngbDropdownToggle
      class="profile_logo"
      [firstName]="user.firstName"
      [lastName]="user.lastName"
      [userId]="user.userId || ''">
    </app-user-avatar>
    <div ngbDropdownMenu class="button-drop-down-menu">
      <button
        data-testid="link_view_profile_header_user_menu"
        ngbDropdownItem
        [routerLinkActive]="['main_menu--active']"
        [routerLink]="['settings/profile']">
        <span>{{ translate("viewProfile") }}</span>
      </button>
      <a
        ngbDropdownItem
        target="_blank"
        data-testid="link_support_header_user_menu"
        href="https://yourtrust.zendesk.com/"
        class="support-link">
        <span>{{ translate("support") }}</span>
        <svg>
          <use xlink:href="#support"></use>
        </svg>
      </a>
      <button data-testid="link_log_out_header_user_menu" ngbDropdownItem (click)="logout()">
        <span>{{ translate("logOut") }}</span>
        <svg>
          <use xlink:href="#log-out"></use>
        </svg>
      </button>
    </div>
  </ng-container>
</div>
