import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Division } from '@incendi-io/types';

import { appConstants } from '../../app.constants';
import { AuthService } from '../oauth0/oauth0.service';
import { BaseFirebaseService } from './base-firebase.service';

@Injectable({
  providedIn: 'root'
})
export class DivisionService extends BaseFirebaseService<Division> {
  constructor(
    auth: AuthService,
    db: AngularFirestore
  ) {
    super(auth, db, appConstants.firebase.collections.divisions);
  }
}
