import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { from, Observable } from 'rxjs';

import { ConfirmComponent } from './confirm.component';
import { ConfirmButton, ConfirmOptions } from './confirm.interface';

@Injectable()
export class ConfirmService {
  private static defaultButtons: ConfirmButton[] = [
    {
      class: 'btn btn-lg btn-info',
      focused: true,
      label: 'noCancel',
      value: false
    },
    {
      class: 'btn btn-lg btn-danger',
      icon: 'delete',
      label: 'yesDelete',
      value: true
    }
  ];

  constructor(private modal: NgbModal) {}

  public open(options: ConfirmOptions): Observable<any> {
    const modal = this.modal.open(ConfirmComponent, {
      centered: true,
      windowClass: 'your-trust-details-modal confirm-modal' + ` ${options.panelClass || ''}`,
      backdrop: options.backdrop,
      keyboard: options.keyboard
    });
    const buttons = options?.buttons || ConfirmService.defaultButtons;

    if (options?.buttonLabels?.length) {
      buttons.forEach((btn, index) => {
        if (options.buttonLabels?.[index]) {
          btn.label = options.buttonLabels[index];
        }
      });
    }

    (modal.componentInstance as ConfirmComponent).label = options.label;
    (modal.componentInstance as ConfirmComponent).params = options.params;
    (modal.componentInstance as ConfirmComponent).message = options.message || '';
    (modal.componentInstance as ConfirmComponent).item = options.item || '';
    (modal.componentInstance as ConfirmComponent).icon = options.icon || '';
    (modal.componentInstance as ConfirmComponent).buttons = buttons;
    (modal.componentInstance as ConfirmComponent).inputPlaceholder = options.inputPlaceholder;
    (modal.componentInstance as ConfirmComponent).showInput = options.showInput;
    (modal.componentInstance as ConfirmComponent).coloredHeader = options.coloredHeader || false;
    (modal.componentInstance as ConfirmComponent).hideClose = options.hideClose;
    return from(modal.result.catch(() => false));
  }
}
