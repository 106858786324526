<app-icons></app-icons>

<div class="main">
  <app-navbar *ngIf="showNavbar && isLoggedIn" class="top-bar"></app-navbar>

  <div *ngIf="showLoader" class="loader_main">
    <div class="loader"></div>
  </div>

  <div class="content" [perfectScrollbar]>
    <router-outlet (activate)="onActivate()"></router-outlet>
  </div>
</div>
