import { registerLocaleData } from '@angular/common';
import localeMs from '@angular/common/locales/ms';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics/';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgbDropdownModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { CookieService } from 'ngx-cookie-service';
import { NgxMaskModule } from 'ngx-mask';
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule
} from 'ngx-perfect-scrollbar';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { appInitializer } from './app.initializer';
import { ApiModule } from './core/api/api.module';
import { AuthService } from './core/oauth0/oauth0.service';
import { AlgoliaService } from './core/services/algolia.service';
import { GtmService } from './core/services/gtm.service';
import { TenantsService } from './core/services/tenant.service';
import { UsersService } from './core/services/users.service';
import { LogoutComponent } from './logout/logout.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { ConfirmModule } from './shared/confirm/confirm.module';
import { IconsModule } from './shared/icons/icons.module';
import { ProfileHeaderComponent } from './shared/profile-header/profile-header.component';
import { SharedModule } from './shared/shared.module';
import { TranslocoRootModule } from './transloco/transloco-root.module';

registerLocaleData(localeMs);

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  bootstrap: [
    AppComponent
  ],
  declarations: [
    AppComponent,
    LogoutComponent,
    NavBarComponent,
    ProfileHeaderComponent
  ],
  imports: [
    AngularFireAnalyticsModule,
    AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireStorageModule,
    AngularFirestoreModule,
    BrowserAnimationsModule,
    BrowserModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgbDropdownModule,
    NgbPopoverModule,
    NgxMaskModule.forRoot(),
    PerfectScrollbarModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),

    ApiModule,
    AppRoutingModule,
    ConfirmModule,
    IconsModule,
    SharedModule,
    TranslocoRootModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      deps: [AuthService, UsersService, TenantsService, AlgoliaService, GtmService],
      multi: true
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {
      provide: LOCALE_ID,
      deps: [TranslocoService],
      useFactory: (translocoService: TranslocoService) => translocoService.getActiveLang()
    },
    CookieService
  ]
})
export class AppModule {
}
