import { ChangeDetectionStrategy, Component, HostBinding, OnInit, Self } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ApprovalRequest, TeamSearch, User } from '@incendi-io/types';
import { Observable } from 'rxjs';
import { filter, mapTo, tap } from 'rxjs/operators';

import { appConstants } from '../app.constants';
import { AuthService } from '../core/oauth0/oauth0.service';
import { AlgoliaService } from '../core/services/algolia.service';
import { ApprovalRequestService } from '../core/services/approval-request.service';
import { AutoUnsubscribeService } from '../core/services/auto-unsubscribe.service';
import { TeamsService } from '../core/services/teams.service';
import { ROUTER_LINKS } from '../interfaces/router-links.enum';
import { UserRoles } from '../interfaces/user-roles.enum';

interface TeamMenuLocation {
  location: string;
  teams: TeamSearch[];
}

const noHeaderRoutes = [
  /form-builder\/.+/,
  /forms\/.+/
];

@Component({
  selector: 'app-navbar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
  providers: [AutoUnsubscribeService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavBarComponent implements OnInit {
  currentTeam: TeamSearch | undefined;
  public locations: TeamMenuLocation[] = [];
  public isAdmin = false;
  public isEnterprise = false;
  public giftsForApproval$?: Observable<ApprovalRequest[]>;

  private hidden = false;

  get homeLink(): string {
    return ROUTER_LINKS.HOME;
  }

  get reportsLink(): string {
    return ROUTER_LINKS.REPORTS;
  }

  get staffLink(): string {
    return ROUTER_LINKS.STAFF;
  }

  get teamLink(): string {
    return ROUTER_LINKS.TEAM;
  }

  get reviewLink(): string {
    return ROUTER_LINKS.REGISTRY_REVIEW;
  }

  get analyticsLink(): string {
    return ROUTER_LINKS.ANALYTICS;
  }

  get previewContentLink(): string {
    return ROUTER_LINKS.PREVIEW;
  }

  @HostBinding('class.hidden') get isHidden(): boolean {
    return this.hidden;
  }

  constructor(
    @Self() private unsub: AutoUnsubscribeService,
    private algoliaService: AlgoliaService,
    private auth: AuthService,
    private approvalRequestService: ApprovalRequestService,
    private teamService: TeamsService,
    private router: Router
  ) {
  }

  public ngOnInit(): void {
    this.unsub.subs = this.searchTeams().subscribe();
    this.giftsForApproval$ = this.approvalRequestService.checkGiftsForApproval({id: this.auth.id, ...this.auth.profile} as User);
    this.isAdmin = this.auth.profile?.role === UserRoles.Administrator;
    this.isEnterprise = this.auth.tenant!.enterprise || false;

    this.unsub.subs = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(event => {
        const { url } = event as NavigationEnd;
        noHeaderRoutes.some(route => {
          this.hidden = route.test(url);
          return this.hidden;
        });
      });
  }

  getCurrentTeam(): void {
    const foundLocation = this.locations.find(loc => {
      return loc.teams.find(team => team.objectID === this.auth.profile?.teamId);
    });

    if (foundLocation) {
      this.currentTeam = foundLocation.teams.find(team => team.objectID === this.auth.profile?.teamId);
    }
  }

  private searchTeams(): Observable<void> {
    return this.algoliaService
      .search<TeamSearch>(appConstants.algolia.indices.teams, '')
      .pipe(
        tap(res => {
          const groupedByLocation = res.hits.reduce((r, a) => {
              r[a.location] = r[a.location] || [];
              r[a.location].push(a);
              return r;
            }, {} as any
          );

          this.locations = Object.keys(groupedByLocation).map((key) => {
            return { location: key, teams: groupedByLocation[key] };
          });

          this.getCurrentTeam();
          this.selectTeam(this.currentTeam);
        }),
        mapTo(void 0)
      );
  }

  selectTeam(team: TeamSearch | undefined) {
    if (this.currentTeam !== team) {
      this.currentTeam = team;
    }
    if (team) {
      this.teamService.switchTeam(team.firestoreId);
    }
  }
}
