import { environment } from '../../../environments/environment';

declare const zE: any;

export class ZendeskService {
  public static initialize(fullName: string = '', email: string = ''): void {
    const script = document.createElement('script') as HTMLScriptElement;

    script.onload = () => {
      if (zE) {
        zE('webWidget', 'prefill', {
          name: {
            value: fullName || ''
          },
          email: {
            value: email || ''
          }
        });
      }
    };

    script.id = 'ze-snippet';
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${environment.zendeskKey}`;
    document.body.appendChild(script);
  }
}
