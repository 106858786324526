<a [routerLink]="link" [queryParams]="qParams" [attr.data-testid]="'news_card_' + news?.id">
  <figure [class.recent]="recent">
    <div class="image-block">
      <div class="image-container">
        <svg *ngIf="!recent" xmlns="http://www.w3.org/2000/svg" height="240" width="418"></svg>
        <svg *ngIf="recent" xmlns="http://www.w3.org/2000/svg" height="240" width="360"></svg>
        <img [src]="news?.image" alt="" (error)="imgError()" (load)="load()" [class.has-error]="isError" />
      </div>
    </div>

    <figcaption>
      <p class="news-title">{{ news?.title }}</p>
      <p class="author text-truncate">
        <span>{{ news?.date | date: "dd MMM yyyy" }}</span>
      </p>
    </figcaption>
  </figure>
</a>
