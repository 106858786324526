import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { AnnouncementCard, ContentfulService } from '../../../core/services/contentful.service';
import { ROUTER_LINKS } from '../../../interfaces/router-links.enum';

@Component({
  selector: 'app-announcement-card',
  templateUrl: './announcement-card.component.html',
  styleUrls: ['./announcement-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnnouncementCardComponent {
  @Input() public announcement!: AnnouncementCard;
  @Input() public routeRoot = ROUTER_LINKS.HOME_CONTENT;
  @Input() public index = 0;
  public get isRecent(): boolean {
    return ContentfulService.isAnnouncementRecent(this.announcement);
  }

  public get link(): string {
    return `/${this.routeRoot}/announcements/${this.announcement.id}`;
  }

  public get qParams(): { [k: string]: any } {
    return {
      title: this.announcement.title
    };
  }
}
