import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-table-state-watermark',
  templateUrl: './table-state-watermark.component.html',
  styleUrls: ['./table-state-watermark.component.scss']
})
export class TableStateWatermarkComponent {
  @Input() icon = 'no-data-found';
  @Input() title = 'noResult';
  @Input() description = 'tryToAdjust';
}
